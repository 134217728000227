import React, { useEffect, useState } from "react";
import Modal from "@mui/material/Modal";
import { Card, OutlinedBox, SubText } from "../styles";
import styled from "styled-components";
import toast from "react-hot-toast";
import { doc, getDoc, increment, updateDoc } from "firebase/firestore";
import { db } from "../firebase/firebase";
// const DepositOptions = ["Pay to Wallet Address", "Pay With Coinbase Commerce", "PayWithMetamask"]

const ShowFXDetailsModal = ({ open }) => {
  const { showDetails, setShowDetails } = open;
  const [isSubmitting, setIsSubmitting] = useState(false);

  // const { actualEntry, actualPrice, actualTP, actualSL, pips, pnl } = stats;

  // function handleClose() {
  //   setIsSubmitting(true);
  //   filter(trade);
  // }

  // async function filter(trade) {
  //   const docRef = doc(db, "trades", trade.ref);
  //   const docSnap = await getDoc(docRef);
  //   if (docSnap.exists()) {
  //     const data = docSnap.data();
  //     if (data.status === "closed") {
  //       toast.error("Trade has already closed.");
  //       setIsSubmitting(false);
  //       return;
  //     } else {
  //       closeFXTrade(trade);
  //     }
  //   } else {
  //     toast.error("Trade could not be found.");
  //     setIsSubmitting(false);
  //   }
  // }

  // async function closeFXTrade(trade) {
  //   const selected = doc(db, "trades", trade.ref);
  //   const closeDetails = {
  //     manualClose: true,
  //     closePrice: actualPrice,
  //     entry: actualEntry,
  //     margin: trade.details.margin,
  //     pnl,
  //   };
  //   await updateDoc(selected, {
  //     status: "closed",
  //     closeDetails,
  //   })
  //     .then(() => {
  //       const gross = Number(trade.details.margin) + Number(pnl);
  //       incrementUser(trade, gross);
  //     })
  //     .catch((error) => {
  //       toast.error(
  //         "There was a problem with your request.\n \n Please try again later."
  //       );
  //       setIsSubmitting(false);
  //     });
  // }

  // async function incrementUser(trade, pnl) {
  //   const q = doc(db, "accounts", trade.user);
  //   const key = `${trade.details.account}.Fiat.value`;
  //   try {
  //     await updateDoc(q, {
  //       [key]: increment(Number(pnl)),
  //     }).then(() => {
  //       toast.success("Trade successfully closed");
  //       setIsSubmitting(false);
  //     });
  //   } catch (error) {
  //     console.log(error);
  //   }
  // }

  return (
    <Modal
      open={showDetails}
      onClose={() => setShowDetails(false)}
      style={{
        color: "white",
        placeContent: "center",
        display: "flex",
      }}
    >
      <Card
        style={{
          placeSelf: "center",
          maxWidth: "360px",
        }}
      >
        <div className="top">
          <SubText>Trade</SubText>

          <button
            style={{
              backgroundColor: "transparent",
              border: "none",
              outline: "none",
            }}
            onClick={() => setShowDetails(false)}
          >
            <img
              src="/sidebaricons/close.svg"
              alt="close"
              className="close"
              height={10}
              width={10}
            />
          </button>
        </div>
        <ModalContent>
          {/* {info.map((inf) => (
            key={inf.name} */}
            <OutlinedBox className="box" >
              {/* {inf.name === "Action" && ( */}
              {/* {inf.name} */}
                <span className="option">
                  <p className="blue textBold">Entry Price</p>
                  <p
                    style={{ fontSize: "14px" }}
                    // className={inf.value === "Buy" ? "green" : "red"}
                  >
                    {/* {inf.value} */}
                  $2000
                  </p>
                </span>
              {/* )} */}

              {/* {inf.name === "PnL" && (
                <span className="option">
                  <p className="blue textBold">{inf.name}:</p>
                  <p
                    style={{ fontSize: "14px" }}
                    className={Number(inf.value) > 0 ? "green" : "red"}
                  >
                    {inf.value}
                  </p>
                </span>
              )}

              {inf.name !== "Action" && inf.name !== "PnL" && (
                <span className="option">
                  <p className="blue textBold">{inf.name}:</p>
                  <p style={{ fontSize: "14px" }}>{inf.value}</p>
                </span>
              )} */}
            </OutlinedBox>
          {/* ))} */}

          <br />
          {/* {trade.status === "open" && (
            <button
              style={{ display: "grid", placeContent: "center" }}
              className="actionButton button"
              onClick={handleClose}
            >
              {isSubmitting ? (
                <img
                  src="/svg-loaders/tail-spin.svg"
                  alt="loading"
                  width={24}
                  height={24}
                />
              ) : (
                <p className="actionText">Close Trade</p>
              )}
            </button>
          )} */}

          {/* {trade.status === "closed" && (
            <button
              className="actionButton button"
              onClick={() => setShowDetails(false)}
            >
              <p className="actionText">Hide Details</p>
            </button>
          )} */}
        </ModalContent>
      </Card>
    </Modal>
  );
};

const ModalContent = styled.div`
  padding: 1.5rem 1.3rem;
  display: grid;
  gap: 1rem;

  .box {
    border-radius: 4px;
  }

  .option {
    display: grid;
    gap: 0.35rem;
    align-items: center;
    font-weight: 600;
    color: #c9c9c9;
    font-size: 14px;

    img {
      width: 20px;
      height: auto;
    }
  }
`;

const ModalOption = styled.div``;

export default ShowFXDetailsModal;
