import { useEffect, useState } from "react";
import styled from "styled-components";
import { Menu, FeatureBtn, FeatureText } from "../styles";
import CountUp from "react-countup";
import { userContext } from "../context/userContext";
import { useContext } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../firebase/firebase";

const MainCard = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const { currentAccount, totalBalance, dispatch } = useContext(userContext);

  function handleMenuOpen() {
    setMenuOpen(!menuOpen);
  }

  function switchAccount() {
    dispatch({
      type: "switchAccount",
      payload: currentAccount === "live" ? "practice" : "live",
    });
    setMenuOpen(false);
  }

  const [isReveal, setIsReveal] = useState(true);

  function handleReveal(e) {
    e.preventDefault();

    setIsReveal(!isReveal);
  }

  return (
    <Wrapper>
      <div className="left">
        <FeatureBtn>
          <img src="/sidebaricons/assets.svg" alt="wallet" />
        </FeatureBtn>
        <FeatureText>
          <span className="flex gap-1 items-center">
            <p className="topText smallText font-medium">Total Balance</p>
            <button onClick={handleReveal} className="animate-pulse">
              <img
                src={isReveal ? "/eyes/reveal.svg" : "/eyes/hide.svg"}
                alt="show-password"
                width={13}
              />
            </button>
          </span>
          {isReveal ? (
            <CountUp
              end={totalBalance}
              duration={1}
              delay={0}
              separator=","
              prefix="$"
            >
              {({ countUpRef }) => (
                <div>
                  <span className="bottomText bigText" ref={countUpRef} />
                </div>
              )}
            </CountUp>
          ) : (
            <span className="bottomText bigText">*****</span>
          )}
        </FeatureText>
      </div>

      <div className="right">
        {currentAccount ? (
          <button
            className={
              currentAccount === "practice" ? "switch practice" : "switch live"
            }
            onClick={handleMenuOpen}
          >
            {currentAccount}
          </button>
        ) : (
        
          <button className="switch practice" onClick={handleMenuOpen}>
            Practice
          </button>
        )}

        <Menu
          onClick={switchAccount}
          className="switchMenu"
          style={{ display: menuOpen ? "flex" : "none" }}
        >
          Switch to {currentAccount === "live" ? "practice" : "live"} Account
        </Menu>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  box-sizing: border-box;
  width: 100%;
  display: flex;
  justify-content: space-between;
  height: 149px;
  padding: 1rem 10px;
  padding-bottom: 1rem;
  border-radius: 10px;
  background: #131824;

  .left {
    display: flex;
    gap: 0.5rem;
  }

  .smallText {
    font-size: 13px;
  }

  .bigText {
    font-size: 20px;
  }

  .right {
    position: relative;

    button {
      cursor: pointer;
    }
  }

  .switchMenu {
    background-color: #1f273a;
    padding: 1rem;
    top: 23px;
    font-weight: 600;
    text-transform: capitalize;
    position: absolute;
    top: 30px;
    right: 0;
    border: 1px solid #19202f;
    cursor: pointer;
  }

  .switch {
    border: none;
    text-transform: capitalize;
    font-weight: 600;
    padding: 0.5rem;
    border-radius: 0.25rem;
    cursor: pointer;
  }

  .practice {
    color: #f7931a;
    background-color: #403529;
  }

  .live {
    color: #20bca4;
    background-color: #20383c;
  }

  .title {
    /* font-size: 1rem; */
  }

  .bigText {
    font-size: 20px;
  }
`;

export default MainCard;
