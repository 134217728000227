import { Menu, SubText, Switches } from "../styles";
import { useEffect, useState } from "react";
import styled from "styled-components";
import { collection, query, getDocs, where, onSnapshot } from "firebase/firestore";
import { db } from "../firebase/firebase";
import CircularLoader from "../pages/CircularLoader";
import ShowFXDetailsModal from "../modals/ShowFXDetailsModal";
import axios from "axios";
import TradeModal from "../modals/TradeModal";
import { useMediaQuery } from "@mui/material";

const TradeHistory = ({ user }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [trades, setTrades] = useState([]);
  const [users, setUsers] = useState([]);
  const [showDetails, setShowDetails] = useState(false);
  const [tradeDetails, setTradeDetails] = useState([]);
  const [tradeAsset, setTradeAsset] = useState("");
  const [tradeInfo, setTradeInfo] = useState([]);
  const [tradeStats, setTradeStats] = useState({});
  const [showRegularTradeDetails, setShowRegularTradeDetails] = useState(false);
  const [regDetails, setRegDetails] = useState([]);
  const [regType, setRegType] = useState("");

  const switchButtons = ["Open", "Closed"];
  const mobile = useMediaQuery("(max-width: 900px)");
  const [activeButton, setActiveButton] = useState("Open")

  const month = ["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"];

  const fxPairs = {
    "AUD/CAD": { name: "AUDCAD", symbol: "AUD/CAD" },
    "AUD/NZD": { name: "AUDNZD", symbol: "AUD/NZD" },
    "AUD/JPY" :{ name: "AUDJPY", symbol: "AUD/JPY" },
    "AUD/USD": { name: "AUDUSD", symbol: "AUD/USD" },

    "CHF/JPY": { name: "CHFJPY", symbol: "CHF/JPY" },

    "EUR/USD": { name: "EURUSD", symbol: "EUR/USD" },
    "EUR/CAD": { name: "EURCAD", symbol: "EUR/CAD" },
    "EUR/AUD": { name: "EURAUD", symbol: "EUR/AUD" },
    "EUR/JPY":  { name: "EURJPY", symbol: "EUR/JPY" },
    "EUR/CHF": { name: "EURCHF", symbol: "EUR/CHF" },
    "EUR/GBP":{ name: "EURGBP", symbol: "EUR/GBP" },

    "GBP/USD": { name: "GBPUSD", symbol: "GBP/USD" },
    "GBP/CHF": { name: "GBPCHF", symbol: "GBP/CHF" },
    "GBP/JPY": { name: "GBPJPY", symbol: "GBP/JPY" },

    "NZD/USD": { name: "NZDUSD", symbol: "NZD/USD" },

    "USD/CAD": { name: "USDCAD", symbol: "USD/CAD" },
    "USD/CHF": { name: "USDCHF", symbol: "USD/CHF" },
    "USD/GBP": { name: "USDGBP", symbol: "USD/GBP" },
    "USD/JPY": { name: "USDJPY", symbol: "USD/JPY" },
  };


  // Create our number formatter.
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  
    // These options are needed to round to whole numbers if that's what you want.
    //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
  });
  
   /* $2,500.00 */
  
    function getTime(milliSeconds) {
      const seconds = milliSeconds / 1000;
      const minutes = seconds / 60;
      const hours = minutes / 60;
      const days = hours / 24;
      const weeks = days / 7;
  
      if (seconds > 60 && minutes < 60) {
        return (
          Math.floor(minutes) +
          (Math.floor(minutes) > 1 ? " mins" : " min")
        );
      }
  
      if (seconds > 60 && minutes > 60 && hours < 24) {
        return (
          Math.floor(hours) + (Math.floor(hours) > 1 ? " hours" : " hour")
        );
      }
  
      if (seconds > 60 && minutes > 60 && hours > 24 && days < 7) {
        return (
          Math.floor(days) + (Math.floor(days) > 1 ? " days" : " day")
        );
      }
  
      if (seconds > 60 && minutes > 60 && hours > 24 && days > 7) {
        return (
          Math.floor(weeks) + (Math.floor(weeks) > 1 ? " weeks" : " week")
        );
      }
  
      return (
        Math.floor(seconds) +
        (Math.floor(seconds) > 1 ? " secs" : " sec")
      );
    }

  useEffect(() => {
    const tradesList = [];
    async function getTrades() {
      const q = query(collection(db, "trades"), where("user", "==", user.uid));
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        tradesList.push(doc.data());
        setTrades(tradesList);
      });
    }
    getTrades();
  }, [user]);

  const [closedTrades, setClosedTrades] = useState([]);
  const [pendingTrades, setPendingTrades] = useState([]);

  useEffect(() => {
    const closedTradesList = [];
    const pendingTradesList = [];

    trades.forEach((trade) => {
      switch (trade.status) {
        case "open":
          pendingTradesList.push(trade);
          break;
        case "closed":
          closedTradesList.push(trade);
          break;
        default:
          break;
      }
    });

    setClosedTrades(closedTradesList);
    setPendingTrades(pendingTradesList);
  }, [trades, pendingTrades, closedTrades]);

  useEffect(() => {
    if (!trades) {
      setIsLoading(true);
    }

    if (trades) {
      setTimeout(() => {
        setIsLoading(false);
      }, 1000);
    }
  }, [trades]);

  function handleShowDetails(trade) {
    switch (trade.type) {
      case "Forex":
        setTradeInfo(trade);
        showFX(trade);
        break;
      default:
        break;
    }
  }

  async function showFX(trade) {
    const pairsSplit = trade.asset.split("/");
    const Base = pairsSplit[0];
    const Quote = pairsSplit[1];

    const url = `https://api.fastforex.io/fetch-one?from=${Base}&to=${Quote}&api_key=18bfe4aa78-c27909660a-rd9m18`;
    const config = {
      method: "get",
    };

    try {
      const response = await axios.get(url, config);
      if (response) {
        const { result } = response.data;
        let current = Object.values(result);
        showFXDetails(...current, trade, Base, Quote);
      }
    } catch (error) {
      console.log("error", error);
    }
  }

  async function showFXDetails(current, trade, Base, Quote) {
    if (trade.status === "open") {
      if (trade.action === "Buy") {
        let actualEntry;
        let actualPrice;
        let actualTP;
        let actualSL;
        let pips;
        let pnl;
        let info;
        let stats;
        switch (Quote) {
          case "JPY":
            actualEntry = Number(trade.price).toFixed(2);
            actualPrice = Number(current).toFixed(2);
            actualSL = trade.details.sl
              ? Number(trade.details.sl).toFixed(2)
              : "Unavailable";
            actualTP = trade.details.tp
              ? Number(trade.details.tp).toFixed(2)
              : "Unavailable";
            pips = (actualPrice - actualEntry) * 100;
            pnl =
              Number(trade.details.perpip) *
              Number(pips) *
              Number(trade.details.amount);
            info = [
              {
                name: "Entry Price",
                value: actualEntry,
              },
              {
                name: "Asset",
                value: trade.asset,
              },
              {
                name: "Action",
                value: trade.action,
              },
              {
                name: "Current Price",
                value: actualPrice,
              },
              {
                name: "PnL",
                value: Number(pnl).toFixed(2),
              },
              {
                name: "TP",
                value: actualTP,
              },
              {
                name: "SL",
                value: actualSL,
              },
            ];
            stats = {
              actualEntry,
              actualPrice,
              actualTP,
              actualSL,
              pips,
              pnl,
              info,
            };

            setTradeStats(stats);
            setTradeDetails(info);
            setTradeAsset(trade.asset);
            setShowDetails(true);
            break;
          default:
            actualEntry = Number(trade.price).toFixed(4);
            actualPrice = Number(current).toFixed(4);
            actualSL = trade.details.sl
              ? Number(trade.details.sl).toFixed(4)
              : "Unavailable";
            actualTP = trade.details.tp
              ? Number(trade.details.tp).toFixed(4)
              : "Unavailable";
            pips = (actualPrice - actualEntry) * 10000;
            pnl =
              Number(trade.details.perpip) *
              Number(pips) *
              Number(trade.details.amount);
            info = [
              {
                name: "Entry Price",
                value: actualEntry,
              },
              {
                name: "Asset",
                value: trade.asset,
              },
              {
                name: "Action",
                value: trade.action,
              },
              {
                name: "Current Price",
                value: actualPrice,
              },
              {
                name: "PnL",
                value: Number(pnl).toFixed(2),
              },
              {
                name: "TP",
                value: actualTP,
              },
              {
                name: "SL",
                value: actualSL,
              },
            ];
            stats = {
              actualEntry,
              actualPrice,
              actualTP,
              actualSL,
              pips,
              pnl,
              info,
            };

            setTradeStats(stats);
            setTradeDetails(info);
            setTradeAsset(trade.asset);
            setShowDetails(true);
            break;
        }
      }
      if (trade.action === "Sell") {
        let actualEntry;
        let actualPrice;
        let actualTP;
        let actualSL;
        let pips;
        let pnl;
        let info;
        let stats;
        switch (Quote) {
          case "JPY":
            actualEntry = Number(trade.price).toFixed(2);
            actualPrice = Number(current).toFixed(2);
            actualSL = trade.details.sl
              ? Number(trade.details.sl).toFixed(2)
              : "Unavailable";
            actualTP = trade.details.tp
              ? Number(trade.details.tp).toFixed(2)
              : "Unavailable";
            pips = (actualEntry - actualPrice) * 100;
            pnl =
              Number(trade.details.perpip) *
              Number(pips) *
              Number(trade.details.amount);
            info = [
              {
                name: "Entry Price",
                value: actualEntry,
              },
              {
                name: "Asset",
                value: trade.asset,
              },
              {
                name: "Action",
                value: trade.action,
              },
              {
                name: "Current Price",
                value: actualPrice,
              },
              {
                name: "PnL",
                value: trade.closeDetails.pnl ? trade.closeDetails.pnl : 0,
              },
              {
                name: "TP",
                value: actualTP,
              },
              {
                name: "SL",
                value: actualSL,
              },
            ];
            stats = {
              actualEntry,
              actualPrice,
              actualTP,
              actualSL,
              pips,
              pnl,
              info,
            };

            setTradeStats(stats);
            setTradeDetails(info);
            setTradeAsset(trade.asset);
            setShowDetails(true);
            break;
          default:
            actualEntry = Number(trade.price).toFixed(4);
            actualPrice = Number(current).toFixed(4);
            actualSL = trade.details.sl
              ? Number(trade.details.sl).toFixed(4)
              : "Unavailable";
            actualTP = trade.details.tp
              ? Number(trade.details.tp).toFixed(4)
              : "Unavailable";
            pips = (actualEntry - actualPrice) * 10000;
            pnl =
              Number(trade.details.perpip) *
              Number(pips) *
              Number(trade.details.amount);
            info = [
              {
                name: "Entry Price",
                value: actualEntry,
              },
              {
                name: "Asset",
                value: trade.asset,
              },
              {
                name: "Action",
                value: trade.action,
              },
              {
                name: "Current Price",
                value: actualPrice,
              },
              {
                name: "PnL",
                value: Number(pnl).toFixed(2),
              },
              {
                name: "TP",
                value: actualTP,
              },
              {
                name: "SL",
                value: actualSL,
              },
            ];
            stats = {
              actualEntry,
              actualPrice,
              actualTP,
              actualSL,
              pips,
              pnl,
              info,
            };
            setTradeStats(stats);
            setTradeDetails(info);
            setTradeAsset(trade.asset);
            setShowDetails(true);
            break;
        }
      }
    }
    if (trade.status === "closed") {
      const info = [
        {
          name: "Entry Price",
          value: trade.details.entry,
        },
        {
          name: "Asset",
          value: trade.asset,
        },
        {
          name: "Action",
          value: trade.action,
        },
        {
          name: "Close Price",
          value: trade.closeDetails.closePrice,
        },
        {
          name: "PnL",
          value: Number(trade.closeDetails.pnl).toFixed(2)
            ? Number(trade.closeDetails.pnl).toFixed(2)
            : 0,
        },
      ];
      setTradeDetails(info);
      setTradeAsset(trade.asset);
      setShowDetails(true);
    }
  }

  function handleShowRegular(type, details) {
    setRegDetails(details);
    setRegType(type);
    setShowRegularTradeDetails(true);
  }

  return (
    <Wrapper className="scrollbar-hidden">
      <SubText className="top" style={{ position: "sticky", zIndex: "10" }}>
        Trades
      </SubText>
      {isLoading ? (
        <CircularLoader />
      ) : trades.length > 0 ? (
        <>
          {showDetails && (
            <ShowFXDetailsModal
              open={{ showDetails, setShowDetails }}
              info={tradeDetails}
              asset={tradeAsset}
              trade={tradeInfo}
              stats={tradeStats}
            />
          )}

          {showRegularTradeDetails && (
            <TradeModal
              details={regDetails}
              type={regType}
              open={{ showRegularTradeDetails, setShowRegularTradeDetails }}
            />
          )}

                <Switches className="switches p-4" style={{maxWidth: !mobile && "500px"}}>
                    {switchButtons.map((button) => (
                      <button
                        key={button}
                        className={activeButton === button ? "active" : ""}
                        onClick={() => setActiveButton(button)}
                        style={{width: "100%"}}
                      >
                        {button}
                      </button>
                    ))}
                  </Switches>

          {/* <table className="scrollbar-hidden">
            <thead>
              <tr>
                <th>Ref</th>
                <th>Type</th>
                <th>Action</th>
                <th>Amount</th>
                <th>Asset</th>
                <th>Entry Price</th>
                <th>Date</th>
                <th>Details</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {pendingTrades.map((trade) =>
                trade.type === "Forex" ? (
                  <tr key={trade.ref} className="row">
                    <td className="ref">
                      <p>{trade.ref}</p>
                    </td>
                    <td>
                      <p>{trade.type}</p>
                    </td>
                    <td>
                      <p className={trade.action === "Buy" ? "green" : "red"}>
                        {trade.action}
                      </p>
                    </td>
                    <td>
                      <p className="green">{trade.amount}</p>
                    </td>
                    <td>
                      <p>{trade.asset}</p>
                    </td>
                    <td>
                      <p className="green">{trade.price} USD</p>
                    </td>
                    <td>
                      <p>{getTime(new Date() - trade.date.toDate())}</p>
                    </td>
                    <td>
                      <button
                        className="actionButton"
                        onClick={() => {
                          handleShowDetails(trade);
                        }}
                      >
                        <p className="actionText">Show</p>
                      </button>
                    </td>
                    <td>
                      {trade.status === "open" && (
                        <button className="approved">
                          <p>Running</p>
                        </button>
                      )}
                    </td>
                  </tr>
                ) : (
                  <tr key={trade.ref} className="row">
                    <td className="ref">
                      <p>{trade.ref}</p>
                    </td>
                    <td>
                      <p>{trade.type}</p>
                    </td>
                    <td>
                      <p className={trade.action === "Buy" ? "green" : "red"}>
                        {trade.action}
                      </p>
                    </td>
                    <td>
                      <p className="green">{trade.amount}</p>
                    </td>
                    <td>
                      <p>{trade.asset}</p>
                    </td>
                    <td>
                      <p className="green">{trade.price} USD</p>
                    </td>
                    <td>
                      <p>{getTime(new Date() - trade.date.toDate())}</p>
                    </td>
                    <td>
                      <button
                        className="actionButton"
                        onClick={() =>
                          handleShowRegular(trade.type, trade.details)
                        }
                      >
                        <p className="actionText">Show</p>
                      </button>
                    </td>
                    <td>
                      {trade.status === "open" && (
                        <button className="approved">
                          <p>Running</p>
                        </button>
                      )}
                    </td>
                  </tr>
                )
              )}
              {closedTrades.map((trade) =>
                trade.type === "Forex" ? (
                  <tr key={trade.ref} className="row">
                    <td className="ref">
                      <p>{trade.ref}</p>
                    </td>
                    <td>
                      <p>{trade.type}</p>
                    </td>
                    <td>
                      <p className={trade.action === "Buy" ? "green" : "red"}>
                        {trade.action}
                      </p>
                    </td>
                    <td>
                      <p className="green">{trade.amount}</p>
                    </td>
                    <td>
                      <p>{trade.asset}</p>
                    </td>
                    <td>
                      <p className="green">{trade.price} USD</p>
                    </td>
                    <td>
                      <p>{getTime(new Date() - trade.date.toDate())}</p>
                    </td>
                    <td>
                      <button
                        className="actionButton"
                        onClick={() => {
                          handleShowDetails(trade);
                        }}
                      >
                        <p className="actionText">Show</p>
                      </button>
                    </td>
                    <td>
                      {trade.status === "closed" && (
                        <button className="declined">
                          <p>Fufilled</p>
                        </button>
                      )}
                    </td>
                  </tr>
                ) : (
                  <tr key={trade.ref} className="row">
                    <td className="ref">
                      <p>{trade.ref}</p>
                    </td>
                    <td>
                      <p>{trade.type}</p>
                    </td>
                    <td>
                      <p className={trade.action === "Buy" ? "green" : "red"}>
                        {trade.action}
                      </p>
                    </td>
                    <td>
                      <p className="green">{trade.amount}</p>
                    </td>
                    <td>
                      <p>{trade.asset}</p>
                    </td>
                    <td>
                      <p className="green">{trade.price} USD</p>
                    </td>
                    <td>
                      <p>{getTime(new Date() - trade.date.toDate())}</p>
                    </td>
                    <td>
                      <button
                        className="actionButton"
                        onClick={() =>
                          handleShowRegular(trade.type, trade.details)
                        }
                      >
                        <p className="actionText">Show</p>
                      </button>
                    </td>
                    <td>
                      {trade.status === "closed" && (
                        <button className="declined">
                          <p>Fufilled</p>
                        </button>
                      )}
                    </td>
                  </tr>
                )
              )}
            </tbody>
          </table> */}

                  <TableWrapper>
                    {/* <SubText className="top">{activeButton}</SubText> */}
                    {activeButton === "Open" && 
                     <table className="desktopTable scrollbar-hidden">
                        {!mobile && (
                          <thead className="desktopHead">
                            <tr className="desktopRow">
                              {/* Desktop */}
                              {/* {!mobile && <th className="star">Star</th>} */}
                              {!mobile && <th className="name">Date</th>}
                              {!mobile && <th className="assetName">Asset</th>}
                              {!mobile && <th className="assetName">Action</th>}
                              {!mobile && <th className="value">Amount</th>}
                              {!mobile && <th className="value">Entry Price</th>}
                              {!mobile && <th className="value">Time Elapsed</th>}
                              {/* {!mobile && (
                                <th className="currentPrice">Current Price</th>
                              )}
                              {!mobile && <th className="currentPrice"></th>} */}
                            </tr>
                          </thead>
                        )}
                        <tbody className="desktopBody">    
                               {pendingTrades?.map(trade => (
                                trade?.type !== "Forex" ?

                                (  <tr
                                  className="desktopRow hover:bg-[#19202f] cursor-pointer"
                                  style={{
                                    width: mobile && "100%",
                                    display: mobile && "flex",
                                    justifyContent: mobile && "space-between",
                                  }}
                                >
                                   {mobile && (
                                    <td
                                      style={{
                                        maxWidth: "30px",
                                        width: "100%",
                                        fontSize: "16px",
                                        justifyItems: "flex-start",
                                        textAlign: "center"
                                      }}
                                    >
                                      <p>
                                        {month[new Date(trade.date * 1000).getMonth()]} 
                                        {" "}
                                        {new Date(trade.date * 1000).getDate()}
                                      </p>
                                    </td>
                                  )}


                                  {mobile && (
                                    <td
                                      className="flex items-center gap-[14px]"
                                      style={{
                                        maxWidth: "100px",
                                        width: "100%",
                                      }}
                                    >
                                      {/* <div className="icon"> */}
                                        <img
                                          src={trade.type === "Stocks" ? `/asseticons/${trade?.asset}.svg` : `/asseticons/${trade?.asset?.slice(0, -3)}.svg` }
                                          alt={trade?.asset}
                                          style={{
                                            width: "24px",
                                            height: "24px",
                                          }}
                                        />
                                      {/* </div> */}
                                      <span className="grid gap-[8px]">
                                        <p className="text-[14px] uppercase">
                                          {trade.action} {trade?.action === "Sell" ? trade.amount : trade.converted} {trade?.asset}
                                        </p>
                                        <p className="text-[16px] text-[#a3a3a3]">
                                         {formatter.format(trade?.price)}
                                        </p>
                                      </span>
                                    </td>
                                  )}

                                  {mobile && (
                                    <td
                                    className= { trade?.action === "Buy" ? "green" : "red"}
                                      style={{
                                        maxWidth: "60px",
                                        width: "100%",
                                        fontSize: "14px",
                                        justifyItems: "flex-start",
                                      }}
                                    >
                                      <p>
                                       {/* $0.00 */}
                                       ${trade.profit ? trade.profit : "0.00"}
                                      </p>

                                      <p className="text-white">
                                       {getTime(new Date() - trade?.date.toDate())}
                                      </p>
                                    </td>
                                  )}

                                  {!mobile && (
                                    <td className="name">
                                      {month[new Date(trade.date * 1000).getMonth()]} {new Date(trade.date * 1000).getDate()}
                                    </td>
                                  )}


                                  {!mobile && (
                                    <td className="asset">
                                      {/* <span className="icon"> */}
                                        <img
                                          src={trade.type === "Stocks" ? `/asseticons/${trade?.asset}.svg` : `/asseticons/${trade?.asset?.slice(0, -3)}.svg` }
                                          alt=""
                                        />
                                      {/* </span> */}
                                      <p>{trade.asset}</p>
                                    </td>
                                  )}

                                  {!mobile && (
                                    <td>
                                      <p className="uppercase">{trade?.action === 'buy' ? 'up' : "down"}</p>
                                    </td>
                                  )}


                                   {!mobile && (
                                    <td>
                                      <p>{trade.amount}</p>
                                    </td>
                                  )}

                                {!mobile && (
                                    <td>
                                      <p>{trade?.price}</p>
                                    </td>
                                  )}


                                  {!mobile && (
                                    <td>
                                      <p> {getTime(new Date() - trade?.date.toDate())}</p>
                                    </td>
                                  )}

                                 
                                 

                                  {/* {!mobile && (
                                    <td className="value">
                                      <CountUp
                                        end={account.value}
                                        duration={1}
                                        delay={0}
                                        separator=","
                                        suffix={" " + account.asset}
                                      >
                                        {({ countUpRef }) => (
                                          <div>
                                            <span
                                              className="bottomText bigText"
                                              ref={countUpRef}
                                            />
                                          </div>
                                        )}
                                      </CountUp>
                                    </td>
                                  )} */}

                                 

                                  {/* {!mobile && (
                                    <td>
                                      <button
                                        className="actionButton"
                                       
                                      >
                                        <p className="actionText">Trade</p>
                                      </button>
                                    </td>
                                  )} */}
                                </tr> )  :  
                                
                                (  <tr
                                  className="desktopRow hover:bg-[#19202f] cursor-pointer"
                                  style={{
                                    width: mobile && "100%",
                                    display: mobile && "flex",
                                    justifyContent: mobile && "space-between",
                                  }}
                                >
                                   {mobile && (
                                    <td
                                      style={{
                                        maxWidth: "30px",
                                        width: "100%",
                                        fontSize: "16px",
                                        justifyItems: "flex-start",
                                        textAlign: "center"
                                      }}
                                    >
                                      <p>
                                        {month[new Date(trade.date * 1000).getMonth()]} 
                                        {" "}
                                        {new Date(trade.date * 1000).getDate()}
                                      </p>
                                    </td>
                                  )}


                                  {mobile && (
                                    <td
                                      className="flex items-center gap-[14px]"
                                      style={{
                                        maxWidth: "100px",
                                        width: "100%",
                                      }}
                                    >
                                        <img
                                          src={`/asseticons/${fxPairs[trade.asset].name}.svg`}
                                          alt={trade?.asset}
                                          style={{
                                            width: "24px",
                                            height: "24px",
                                          }}
                                        />
                                      <span className="grid gap-[8px]">
                                        <p className="text-[14px] uppercase">
                                          {trade.action} {trade?.action === "Sell" ? trade.amount : trade.converted} {trade?.asset}
                                        </p>
                                        <p className="text-[16px] text-[#a3a3a3]">
                                         {formatter.format(trade?.price)}
                                        </p>
                                      </span>
                                    </td>
                                  )}

                                  {mobile && (
                                    <td
                                    className= { trade?.action === "Buy" ? "green" : "red"}
                                      style={{
                                        maxWidth: "60px",
                                        width: "100%",
                                        fontSize: "14px",
                                        justifyItems: "flex-start",
                                      }}
                                    >
                                      <p>
                                      ${trade.profit ? trade.profit : "0.00"}
                                      </p>

                                      <p className="text-white">
                                       {getTime(new Date() - trade?.date.toDate())}
                                      </p>
                                    </td>
                                  )}

                                  {!mobile && (
                                    <td className="name">
                                      {month[new Date(trade.date * 1000).getMonth()]} {new Date(trade.date * 1000).getDate()}
                                    </td>
                                  )}


                                  {!mobile && (
                                    <td className="asset">
                                      {/* <span className="icon"> */}
                                        <img
                                          src={`/asseticons/${fxPairs[trade.asset].name}.svg`}
                                          alt=""
                                        />
                                      {/* </span> */}
                                      <p>{trade.asset}</p>
                                    </td>
                                  )}

                                  {!mobile && (
                                    <td>
                                      <p className="uppercase">{trade?.action === "buy" ? "up" : "down"}</p>
                                    </td>
                                  )}


                                   {!mobile && (
                                    <td className={trade.action === "buy"? "green" : "red"}>
                                      <p>${trade.amount}</p>
                                    </td>
                                  )}

                                {!mobile && (
                                    <td>
                                      <p>{trade?.details.entry}</p>
                                    </td>
                                  )}


                                  {!mobile && (
                                    <td>
                                      <p> {getTime(new Date() - trade?.date.toDate())}</p>
                                    </td>
                                  )}

                                 
                                 

                                  {/* {!mobile && (
                                    <td className="value">
                                      <CountUp
                                        end={account.value}
                                        duration={1}
                                        delay={0}
                                        separator=","
                                        suffix={" " + account.asset}
                                      >
                                        {({ countUpRef }) => (
                                          <div>
                                            <span
                                              className="bottomText bigText"
                                              ref={countUpRef}
                                            />
                                          </div>
                                        )}
                                      </CountUp>
                                    </td>
                                  )} */}

                                 

                                  {/* {!mobile && (
                                    <td>
                                      <button
                                        className="actionButton"
                                       
                                      >
                                        <p className="actionText">Trade</p>
                                      </button>
                                    </td>
                                  )} */}
                                </tr> )
                                ))}
                        </tbody>
                      </table> }


                      {activeButton === "Closed" && 
                     <table className="desktopTable scrollbar-hidden">
                        {!mobile && (
                          <thead className="desktopHead">
                            <tr className="desktopRow">
                              {/* Desktop */}
                              {/* {!mobile && <th className="star">Star</th>} */}
                              {!mobile && <th className="name">Date</th>}
                              {!mobile && <th className="assetName">Asset</th>}
                              {!mobile && <th className="assetName">Action</th>}
                              {!mobile && <th className="value">Amount</th>}
                              {!mobile && <th className="value">Entry Price</th>}
                              {!mobile && <th className="value">Close Price</th>}
                              {/* {!mobile && (
                                <th className="currentPrice">Current Price</th>
                              )}
                              {!mobile && <th className="currentPrice"></th>} */}
                            </tr>
                          </thead>
                        )}
                        <tbody className="desktopBody">    
                               {closedTrades?.map(trade => (
                                trade?.type !== "Forex" ?

                                (  <tr
                                  className="desktopRow hover:bg-[#19202f] cursor-pointer"
                                  style={{
                                    width: mobile && "100%",
                                    display: mobile && "flex",
                                    justifyContent: mobile && "space-between",
                                  }}
                                >
                                   {mobile && (
                                    <td
                                      style={{
                                        maxWidth: "30px",
                                        width: "100%",
                                        fontSize: "16px",
                                        justifyItems: "flex-start",
                                        textAlign: "center"
                                      }}
                                    >
                                      <p>
                                        {month[new Date(trade.date * 1000).getMonth()]} 
                                        {" "}
                                        {new Date(trade.date * 1000).getDate()}
                                      </p>
                                    </td>
                                  )}


                                  {mobile && (
                                    <td
                                      className="flex items-center gap-[14px]"
                                      style={{
                                        maxWidth: "100px",
                                        width: "100%",
                                      }}
                                    >
                                      {/* <div className="icon"> */}
                                        <img
                                           src={trade.type === "Stocks" ? `/asseticons/${trade?.asset}.svg` : `/asseticons/${trade?.ass?.slice(0, -3)}.svg` }
                                          alt={trade?.asset}
                                          style={{
                                            width: "24px",
                                            height: "24px",
                                          }}
                                        />
                                      {/* </div> */}
                                      <span className="grid gap-[8px]">
                                        <p className="text-[14px] uppercase">
                                          {trade.action} {trade?.action === "Sell" ? trade.amount : trade.converted} {trade?.asset}
                                        </p>
                                        <p className="text-[16px] text-[#a3a3a3]">
                                         {formatter.format(trade?.price)}
                                        </p>
                                      </span>
                                    </td>
                                  )}

                                  {mobile && (
                                    <td
                                    className= { trade?.action === "Buy" ? "green" : "red"}
                                      style={{
                                        maxWidth: "60px",
                                        width: "100%",
                                        fontSize: "14px",
                                        justifyItems: "flex-start",
                                      }}
                                    >
                                      <p>
                                       ${trade.profit ? Number(trade.profit).toFixed(2) : "0.00"}
                                      </p>

                                      <p className="text-white">
                                       {/* {getTime(new Date() - trade?.date.toDate())} */}
                                       {trade?.closeDetails?.close && trade.closeDetails.close }
                                       {!trade?.closeDetails?.close && trade.price }
                                      </p>
                                    </td>
                                  )}

                                  {!mobile && (
                                    <td className="name">
                                      {month[new Date(trade.date * 1000).getMonth()]} {new Date(trade.date * 1000).getDate()}
                                    </td>
                                  )}


                                  {!mobile && (
                                    <td className="asset">
                                      {/* <span className="icon"> */}
                                        <img
                                         src={trade.type === "Stocks" ? `/asseticons/${trade?.asset}.svg` : `/asseticons/${trade?.ass?.slice(0, -3)}.svg` }
                                          alt=""
                                        />
                                      {/* </span> */}
                                      <p>{trade.asset}</p>
                                    </td>
                                  )}

                                  {!mobile && (
                                    <td>
                                      <p className="uppercase">{trade?.action === 'buy' ? 'up' : "down"}</p>
                                    </td>
                                  )}


                                   {!mobile && (
                                    <td>
                                      <p>{trade.amount}</p>
                                    </td>
                                  )}

                                {!mobile && (
                                    <td>
                                      <p>{trade?.price}</p>
                                    </td>
                                  )}


                                  {!mobile && (
                                    <td>
                                      {/* <p> {getTime(new Date() - trade?.date.toDate())}</p> */}
                                      <p>{trade?.closeDetails?.close && trade.closeDetails.close }
                                       {!trade?.closeDetails?.close && trade.price }</p>
                                    </td>
                                  )}

                                 
                                 

                                  {/* {!mobile && (
                                    <td className="value">
                                      <CountUp
                                        end={account.value}
                                        duration={1}
                                        delay={0}
                                        separator=","
                                        suffix={" " + account.asset}
                                      >
                                        {({ countUpRef }) => (
                                          <div>
                                            <span
                                              className="bottomText bigText"
                                              ref={countUpRef}
                                            />
                                          </div>
                                        )}
                                      </CountUp>
                                    </td>
                                  )} */}

                                 

                                  {/* {!mobile && (
                                    <td>
                                      <button
                                        className="actionButton"
                                       
                                      >
                                        <p className="actionText">Trade</p>
                                      </button>
                                    </td>
                                  )} */}
                                </tr> )  :  
                                
                                (  <tr
                                  className="desktopRow hover:bg-[#19202f] cursor-pointer"
                                  style={{
                                    width: mobile && "100%",
                                    display: mobile && "flex",
                                    justifyContent: mobile && "space-between",
                                  }}
                                >
                                   {mobile && (
                                    <td
                                      style={{
                                        maxWidth: "30px",
                                        width: "100%",
                                        fontSize: "16px",
                                        justifyItems: "flex-start",
                                        textAlign: "center"
                                      }}
                                    >
                                      <p>
                                        {month[new Date(trade.date * 1000).getMonth()]} 
                                        {" "}
                                        {new Date(trade.date * 1000).getDate()}
                                      </p>
                                    </td>
                                  )}


                                  {mobile && (
                                    <td
                                      className="flex items-center gap-[14px]"
                                      style={{
                                        maxWidth: "100px",
                                        width: "100%",
                                      }}
                                    >
                                        <img
                                          src={`/asseticons/${fxPairs[trade.asset].name}.svg`}
                                          alt={trade?.asset}
                                          style={{
                                            width: "24px",
                                            height: "24px",
                                          }}
                                        />
                                      <span className="grid gap-[8px]">
                                        <p className="text-[14px] uppercase">
                                          {trade.action} {trade?.action === "Sell" ? trade.amount : trade.converted} {trade?.asset}
                                        </p>
                                        <p className="text-[16px] text-[#a3a3a3]">
                                         {formatter.format(trade?.amount)}
                                        </p>
                                      </span>
                                    </td>
                                  )}

                                  {mobile && (
                                    <td
                                    className= { trade?.action === "Buy" ? "green" : "red"}
                                      style={{
                                        maxWidth: "60px",
                                        width: "100%",
                                        fontSize: "14px",
                                        justifyItems: "flex-start",
                                      }}
                                    >
                                      <p>
                                      ${trade.profit ? Number(trade.profit).toFixed(2) : Number(trade.closeDetails.pnl).toFixed(2)}
                                      </p>

                                      <p className="text-white">
                                       {/* {getTime(new Date() - trade?.date.toDate())} */}
                                       {trade?.closeDetails.closePrice}
                                      </p>
                                    </td>
                                  )}

                                  {!mobile && (
                                    <td className="name">
                                      {month[new Date(trade.date * 1000).getMonth()]} {new Date(trade.date * 1000).getDate()}
                                    </td>
                                  )}


                                  {!mobile && (
                                    <td className="asset">
                                      {/* <span className="icon"> */}
                                        <img
                                          src={`/asseticons/${fxPairs[trade.asset].name}.svg`}
                                          alt=""
                                        />
                                      {/* </span> */}
                                      <p>{trade.asset}</p>
                                    </td>
                                  )}

                                  {!mobile && (
                                    <td>
                                      <p className="uppercase">{trade?.action === "buy" ? "up" : "down"}</p>
                                    </td>
                                  )}


                                   {!mobile && (
                                    <td>
                                      <p>{trade.amount}</p>
                                    </td>
                                  )}

                                {!mobile && (
                                    <td>
                                      <p>{trade?.details.entry}</p>
                                    </td>
                                  )}


                                  {!mobile && (
                                    <td>
                                      <p> 
                                        {/* {getTime(new Date() - trade?.date.toDate())} */}
                                      {trade?.closeDetails.closePrice}
                                      </p>
                                    </td>
                                  )}

                                 
                                 

                                  {/* {!mobile && (
                                    <td className="value">
                                      <CountUp
                                        end={account.value}
                                        duration={1}
                                        delay={0}
                                        separator=","
                                        suffix={" " + account.asset}
                                      >
                                        {({ countUpRef }) => (
                                          <div>
                                            <span
                                              className="bottomText bigText"
                                              ref={countUpRef}
                                            />
                                          </div>
                                        )}
                                      </CountUp>
                                    </td>
                                  )} */}

                                 

                                  {/* {!mobile && (
                                    <td>
                                      <button
                                        className="actionButton"
                                       
                                      >
                                        <p className="actionText">Trade</p>
                                      </button>
                                    </td>
                                  )} */}
                                </tr> )
                                ))}
                        </tbody>
                      </table> }

                   
                      </TableWrapper>


        </>
      ) : (
        <p
          style={{
            padding: "4rem",
            fontWeight: "600",
            color: "#a3a3a3",
            textAlign: "center",
            alignSelf: "center",
          }}
        >
          You haven't placed any trades.
        </p>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
  margin-top: 30px;
  background: #131824;
  overflow-x: scroll;
  scroll-snap-type: mandatory;
  border-radius: 10px;
  height: 360px;
  /* overflow-y: hidden; */

  .pending {
    background: rgba(35, 163, 251, 0.13);
    border-radius: 6px;
    border: none;
    outline: none;

    p {
      font-size: 14px;
      line-height: 19px;
      font-weight: 600;
      padding: 0.5rem 1rem;
      color: #1199fa;
    }
  }

  .declined {
    /* border: 0.5px solid #1199fa; */
    border: none;
    border-radius: 6px;
    outline: none;
    background: rgba(230, 75, 96, 0.13);

    p {
      font-size: 14px;
      line-height: 19px;
      font-weight: 600;
      padding: 0.5rem;
      color: #e64b60;
    }
  }

  .approved {
    /* border: 0.5px solid #1199fa; */
    border: none;
    border-radius: 6px;
    outline: none;
    background: rgba(32, 188, 164, 0.13);

    p {
      font-size: 14px;
      line-height: 19px;
      font-weight: 600;
      padding: 0.5rem;
      color: #20bca4;
    }
  }

  .asset {
    display: flex;
    gap: 0.5rem;
    align-items: center;
  }
  .top {
    top: 0;
    width: 100%;
    background-color: #19202f;
    display: flex;
    justify-content: space-between;
    padding: 1rem;
    box-sizing: border-box;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }

  table {
    display: grid;
    gap: 1rem;
    padding: 1rem;
    overflow-x: scroll;
    scroll-snap-type: mandatory;
    width: 100%;
    box-sizing: border-box;
    max-height: 100%;

    .name {
      display: flex;
      align-items: center;
      gap: 0.8rem;
      text-transform: capitalize;

      img {
        border-radius: 50%;
        object-fit: cover;
      }
    }

    .ref,
    .email {
      box-sizing: border-box;

      p {
        text-overflow: ellipsis;
        max-width: 85px;
        overflow: hidden;
      }
    }
  }

  thead {
    border: 1px solid #1f273a;
    box-sizing: border-box;
    border-radius: 4px;
    font-weight: 700;
    color: #828ea1;
    font-size: 14px;
    line-height: 18px;
  }

  tbody {
    font-weight: 510;
    font-size: 14px;
    line-height: 17px;
    color: #ffffff;
    display: grid;
    gap: 0.7rem;

    .row {
      border-radius: 4px;
      cursor: pointer;
    }

    .row:hover {
      background-color: #1f273a;
    }
  }

  th,
  td {
    width: 144px;
    text-align: left;
  }

  tr {
    /* width: 100%; */
    display: flex;
    justify-content: space-between;
    padding: 1rem;
    align-items: center;
    box-sizing: border-box;
  }
`;

const TableWrapper = styled.div`
  position: relative;
  margin-top: 30px;
  background: #131824;
  border-radius: 10px;
  box-sizing: border-box;
  overflow: hidden;

  .mobileValue {
    display: none;
  }

  @media screen and (max-width: 800px) {
    .value,
    .estimate,
    .assetName,
    .currentPrice {
      display: none;
    }

    .mobileValue {
      display: flex;
    }
  }

  .asset {
    display: flex;
    gap: 0.5rem;
    align-items: center;
  }

  .top {
    top: 0;
    width: 100%;
    background-color: #19202f;
    display: flex;
    justify-content: space-between;
    padding: 1rem;
    box-sizing: border-box;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }

  .desktopTable {
    display: grid;
    gap: 1rem;
    padding: 1rem;
    overflow-x: auto;
    width: 100%;
    align-items-center;
    
    th,
    td {
      width: 120px;
      text-align: left;
      box-sizing: border-box;
    }

    .star {
      width: 30px;
    }
  }

  .desktopHead {
    border: 1px solid #1f273a;
    box-sizing: border-box;
    border-radius: 4px;
    font-weight: 700;
    color: #828ea1;
    font-size: 14px;
    line-height: 18px;
  }

  .desktopBody {
    font-weight: 510;
    font-size: 14px;
    line-height: 17px;
    color: #ffffff;
  }

  .desktopRow {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 1rem;
    align-items: center;
  }

  .mobileTable {
    display: grid;
    gap: 1rem;
    padding: 1rem;
    overflow-x: auto;
    width: 100%;

    .mobileBody {
      font-weight: 510;
      font-size: 14px;
      line-height: 17px;
      color: #ffffff;
    }

    td,
    th {
      /* background-color: red; */
      box-sizing: border-box;
      display: flex;
      width: 100px;
    }
  }
`;

export default TradeHistory;
