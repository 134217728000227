import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBgexcvg2ZdMZu9Mh9wGx_NZQokrJ5_wKw",
  authDomain: "global-capitals.firebaseapp.com",
  projectId: "global-capitals",
  storageBucket: "global-capitals.appspot.com",
  messagingSenderId: "1021174603070",
  appId: "1:1021174603070:web:70cf1fc3c2756090fb206f",
};

// const firebaseConfig = {
//   apiKey: "AIzaSyBGq25Hh0nt1zy-G4L32-hYypt6LDS9ULc",
//   authDomain: "finance-test-5f328.firebaseapp.com",
//   projectId: "finance-test-5f328",
//   storageBucket: "finance-test-5f328.appspot.com",
//   messagingSenderId: "261981000578",
//   appId: "1:261981000578:web:ee913ada0c961a2803759b",
// };

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);
export const storage = getStorage(app);
