import { useMediaQuery } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { userContext } from "../context/userContext";
// import CircularLoader from "../pages/CircularLoader";
import { Switches } from "../styles";
import CountUp from "react-countup";
import ChooseDepositAccountModal from "../pages/ChooseDepositAccountModal";
import { useNavigate } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth, db } from "../firebase/firebase";
import { doc, onSnapshot } from "firebase/firestore";

const MiniAssetTable = () => {
  const [cryptoAccounts, setCryptoAccounts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { currentPrices, currentAccount } = useContext(userContext);
  const mobile = useMediaQuery("(max-width: 500px)");
  const options = ["Deposit", "Withdraw"];
  const [activeOption, setActiveOption] = useState("Deposit");
  const [depositModal, setDepositModal] = useState(false);
  const navigate = useNavigate();
  const [user] = useAuthState(auth);
  const [accounts, setAccounts] = useState([]);
  const [account, setAccount] = useState("");

  useEffect(() => {
    if (account) {
      const { Crypto } = account;
      const { BTC, ETH } = Crypto;
      if (BTC && ETH) {
        const cryptoAcc = [ETH, BTC];
        if (cryptoAcc) {
          setCryptoAccounts(cryptoAcc);
          setIsLoading(false);
        }
      }
    } else {
      return;
    }
  }, [account, cryptoAccounts]);

  useEffect(() => {
    if (accounts) {
      const { live, practice } = accounts;
      if (live && practice) {
        setAccount(currentAccount === "practice" ? practice : live);
      }
    } else {
      return;
    }
  }, [accounts, currentAccount]);

  useEffect(() => {
    function getAccounts(id) {
      if (id) {
        onSnapshot(doc(db, "accounts", id), (doc) => {
          setAccounts(doc.data());
        });
      }
    }

    if (user) {
      getAccounts(user.uid);
    }
  }, [user]);

  return (
    <>
      {isLoading ? (
        <Wrapper
          style={{
            placeContent: "center",
            backgroundColor: "#19202f",
            boxSizing: "border-box",
            borderRadius: "4px",
          }}
        >
          <img
            src="/svg-loaders/tail-spin.svg"
            alt="loading"
            width={24}
            height={24}
            style={{ display: "flex", padding: "1rem" }}
          />
        </Wrapper>
      ) : (
        <Wrapper>
          <ChooseDepositAccountModal open={{ depositModal, setDepositModal }} />
          {mobile && (
            <Switches style={{ maxWidth: "230px", marginBottom: "0.5rem" }}>
              {options.map((opt) => (
                <button
                  value={opt}
                  key={opt}
                  className={activeOption === opt ? "active" : "switch"}
                  onClick={(e) => setActiveOption(e.target.value)}
                >
                  {opt}
                </button>
              ))}
            </Switches>
          )}
          <table className="table scrollbar-hidden">
            <tbody>
              {cryptoAccounts.map((acc) => (
                <tr key={acc.asset}>
                  <td className="asset">
                    <span className="icon">
                      <img src={`/asseticons/${acc.asset}.svg`} alt="" />
                    </span>
                  </td>
                  <td className="assetName">
                    <p>{acc.name}</p>
                  </td>
                  <td className="value">
                    <CountUp
                      end={acc.value}
                      duration={1}
                      delay={0}
                      separator="."
                      decimals="2"
                      suffix={" " + acc.asset}
                    >
                      {({ countUpRef }) => (
                        <div>
                          <span
                            className="bottomText bigText"
                            ref={countUpRef}
                          />
                        </div>
                      )}
                    </CountUp>
                  </td>
                  <td className="estimate">
                    <CountUp
                      end={currentPrices[acc.asset] * acc.value}
                      duration={1}
                      delay={0}
                      separator=","
                      prefix="$"
                      decimals="2"
                    >
                      {({ countUpRef }) => (
                        <div>
                          <span
                            className="bottomText bigText"
                            ref={countUpRef}
                          />
                        </div>
                      )}
                    </CountUp>
                  </td>
                  <td className="mobile">
                    <CountUp
                      end={currentPrices[acc.asset] * acc.value}
                      duration={1}
                      delay={0}
                      separator=","
                      prefix="$"
                      decimals="2"
                    >
                      {({ countUpRef }) => (
                        <div>
                          <span
                            className="bottomText bigText"
                            ref={countUpRef}
                          />
                        </div>
                      )}
                    </CountUp>
                    <CountUp
                      end={acc.value}
                      duration={1}
                      delay={0}
                      separator="."
                      decimals="2"
                      suffix={" " + acc.asset}
                    >
                      {({ countUpRef }) => (
                        <div>
                          <span
                            className="bottomText  bigText"
                            style={{ color: "#a3a3a3" }}
                            ref={countUpRef}
                          />
                        </div>
                      )}
                    </CountUp>
                    {/* <p className="gray">
                      {acc.value} 
                    </p> */}
                  </td>
                  <td className="currentPrice">
                    <CountUp
                      end={currentPrices[acc.asset]}
                      duration={1}
                      delay={0}
                      separator=","
                      decimals="2"
                      prefix="$"
                      suffix={"/" + acc.asset}
                    >
                      {({ countUpRef }) => (
                        <div>
                          <span
                            className="bottomText bigText"
                            ref={countUpRef}
                          />
                        </div>
                      )}
                    </CountUp>
                  </td>
                  {!mobile && (
                    <td
                      className="actions"
                      style={{ display: "flex", gap: "1.3rem" }}
                    >
                      <button
                        className="actionButton"
                        onClick={() => setDepositModal(!depositModal)}
                        // onClick={() => navigate('/deposit')}
                      >
                        <p className="actionText">Deposit</p>
                      </button>
                      <button
                        className="actionButton"
                        onClick={() => navigate("/withdraw")}
                      >
                        <p className="actionText">Withdraw</p>
                      </button>
                    </td>
                  )}

                  {mobile && (
                    <td style={{ display: "flex", gap: "1.3rem" }}>
                      {activeOption === "Withdraw" ? (
                        <button
                          className="actionButton"
                          onClick={() => navigate("/withdraw")}
                        >
                          <p className="actionText">Withdraw</p>
                        </button>
                      ) : (
                        <button
                          className="actionButton"
                          // onClick={() => setDepositModal(!depositModal)}
                          onClick={() => navigate('/deposit')}
                        >
                          <p className="actionText">Deposit</p>
                        </button>
                      )}
                    </td>
                  )}
                </tr>
              ))}
            </tbody>
          </table>
        </Wrapper>
      )}
    </>
  );
};

const Wrapper = styled.div`
  position: relative;
  display: grid;
  grid-template-rows: auto auto;
  box-sizing: border-box;

  .mobile {
    display: none;
  }

  @media screen and (max-width: 1450px) {
    .name,
    .value,
    .estimate,
    .assetName,
    .currentPrice {
      display: none;
    }

    .mobile {
      display: grid;
    }
  }

  .top {
    top: 0;
    width: 100%;
    background-color: #19202f;
    display: flex;
    justify-content: space-between;
    padding: 1rem;
    box-sizing: border-box;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }

  table {
    display: grid;
    gap: 0.5rem;
    border-radius: 0.5rem;
    padding: 0.3rem;
    height: 100%;
    overflow-x: auto;
    width: 100%;
    background: #131824;
  }

  thead {
    border: 1px solid #1f273a;
    box-sizing: border-box;
    border-radius: 4px;
    font-weight: 700;
    color: #828ea1;
    font-size: 14px;
    line-height: 18px;
  }

  tbody {
    font-weight: 510;
    font-size: 14px;
    line-height: 17px;
    color: #ffffff;
  }

  td {
    /* width: 120px; */
    /* background-color: red; */
    /* text-align: left; */
  }

  .assetName,
  .value,
  .estimate,
  .assetName,
  .mobile {
    width: 100px;
  }

  .value {
    width: 120px;
  }

  .estimate {
    width: 140px;
  }

  .currentPrice {
    width: 120px;
  }

  /* className="actions"  */

  .asset {
    width: 40px;
  }

  tr {
    /* width: 100%; */
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem;
    box-sizing: border-box;
    cursor: pointer;
    border-radius: 8px;
  }

  tr:hover {
    background-color: #1f273a;
  }
`;

export default MiniAssetTable;
