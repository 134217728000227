import React, { useEffect, useState } from "react";
import CircularLoader from "../pages/CircularLoader";
import { doc, deleteDoc } from "firebase/firestore";
import toast from "react-hot-toast";
import { db } from "../firebase/firebase";

const Notifications = ({ data, loading, close, hasNotis }) => {
  const { hasNotifications, setHasNotifications } = hasNotis;
  const { isLoading, setIsLoading } = loading;
  const { noti, setNoti } = close;
  useEffect(() => {
    if (data) {
      setTimeout(() => {
        setIsLoading(false);
      }, 800);
    }
  }, [data]);

  const [showDelete, setShowDelete] = useState(false);

  function getTime(milliSeconds) {
    const seconds = milliSeconds / 1000;
    const minutes = seconds / 60;
    const hours = minutes / 60;
    const days = hours / 24;
    const weeks = days / 7;

    if (seconds > 60 && minutes < 60) {
      return (
        Math.floor(minutes) +
        (Math.floor(minutes) > 1 ? " minutes ago" : " minute ago")
      );
    }

    if (seconds > 60 && minutes > 60 && hours < 24) {
      return (
        Math.floor(hours) + (Math.floor(hours) > 1 ? " hours ago" : " hour ago")
      );
    }

    if (seconds > 60 && minutes > 60 && hours > 24 && days < 7) {
      return (
        Math.floor(days) + (Math.floor(days) > 1 ? " days ago" : " day ago")
      );
    }

    if (seconds > 60 && minutes > 60 && hours > 24 && days > 7) {
      return (
        Math.floor(weeks) + (Math.floor(weeks) > 1 ? " weeks ago" : " week ago")
      );
    }

    return (
      Math.floor(seconds) +
      (Math.floor(seconds) > 1 ? " seconds ago" : " seconds ago")
    );
  }

  async function deleteNoti(ref) {
    try {
      await deleteDoc(doc(db, "notifications", ref));
      setNoti(false);
      setIsLoading(true);
      toast.success("Deleted successfully.");
    } catch (error) {
      toast.error("Please try again later.");
    }
  }

  return (
    <>
      <div>
        <div className="max-w-sm" style={{ textAlign: "left" }}>
          {isLoading && (
            <div className="max-w-[300px] min-w-[250px]">
              <ul className="divide-y divide-gray-200" data-todo-x-max="1">
                <li className="relative py-5 px-4 rounded-lg">
                  <div className="flex justify-between space-x-3">
                    <div className="min-w-0 flex-1">
                      <span
                        className="absolute inset-0"
                        aria-hidden="true"
                      ></span>
                      <p className="text-sm font-semibold text-white truncate">
                        <div className="w-[100px] h-[20px] bg-gray-800 animate-pulse"></div>
                      </p>
                    </div>
                    <time
                      dateTime="2021-01-27T16:35"
                      className="flex-shrink-0 whitespace-nowrap text-sm text-white font-medium"
                    >
                      <div className="w-[40px] h-[20px] bg-gray-800 animate-pulse"></div>
                    </time>
                  </div>
                  <div className="mt-1">
                    <p className="line-clamp-2 text-sm text-[#A3A3A3] mt-2">
                      <div className="w-full h-[20px] bg-gray-800 animate-pulse"></div>
                    </p>
                  </div>
                </li>
                <li className="relative py-5 px-4 rounded-lg">
                  <div className="flex justify-between space-x-3">
                    <div className="min-w-0 flex-1">
                      <span
                        className="absolute inset-0"
                        aria-hidden="true"
                      ></span>
                      <p className="text-sm font-semibold text-white truncate">
                        <div className="w-[100px] h-[20px] bg-gray-800 animate-pulse"></div>
                      </p>
                    </div>
                    <time
                      dateTime="2021-01-27T16:35"
                      className="flex-shrink-0 whitespace-nowrap text-sm text-white font-medium"
                    >
                      <div className="w-[40px] h-[20px] bg-gray-800 animate-pulse"></div>
                    </time>
                  </div>
                  <div className="mt-1">
                    <p className="line-clamp-2 text-sm text-[#A3A3A3] mt-2">
                      <div className="w-full  h-[20px] bg-gray-800 animate-pulse"></div>
                    </p>
                  </div>
                </li>
              </ul>
            </div>
          )}
          {!isLoading && (
            <div className="max-w-[300px] min-w-[250px]">
              <ul className="divide-y divide-gray-200" data-todo-x-max="1">
                {data.map((noti) => (
                  <li className="relative py-5 px-4 rounded-lg  hover:bg-[#1f273a]">
                    <div
                      className="group"
                      onMouseEnter={() => setShowDelete(true)}
                      onMouseLeave={() => setShowDelete(false)}
                    >
                      <div className="flex justify-between space-x-3">
                        <div className="min-w-0 flex-1">
                          <span
                            className="absolute inset-0"
                            aria-hidden="true"
                          ></span>
                          <p className="text-sm font-semibold text-white truncate">
                            {noti.title}
                          </p>
                        </div>
                        <time
                          dateTime="2021-01-27T16:35"
                          className="flex-shrink-0 whitespace-nowrap text-sm text-white font-medium"
                        >
                          {getTime(new Date() - noti.date.toDate())}
                        </time>
                      </div>
                      <div className="mt-1">
                        <p className="line-clamp-2 text-sm text-[#A3A3A3]">
                          {noti.text}
                        </p>
                      </div>
                      {showDelete && (
                        <span
                          onClick={() => deleteNoti(noti.ref)}
                          className="absolute top-[-5px] right-[0px] h-6 w-6 p-2 mb-2 animate-pulse cursor-pointer"
                        >
                          <img src="/sidebaricons/close.svg" alt="delete" />
                        </span>
                      )}
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Notifications;
