import { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import {
  BodyText,
  Menu,
  OutlinedButton,
  FeatureBtn,
  NotificationText,
  ProfileCard,
} from "../styles";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grow from "@mui/material/Grow";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth, db } from "../firebase/firebase";
import CircularLoader from "../pages/CircularLoader";
import {
  collection,
  deleteDoc,
  doc,
  getDocs,
  limit,
  orderBy,
  query,
  where,
} from "firebase/firestore";
import { userContext } from "../context/userContext";
import { Link, useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { signOut } from "firebase/auth";
import Notifications from "./Notifications";

const Header = ({ menu, open }) => {
  const [noti, setNoti] = useState(false);
  const [isOpen, setIsOpen] = open;
  const [profile, setProfile] = useState(false);
  const [user, loading] = useAuthState(auth);
  const [loader, setLoader] = useState(user !== null ? false : true);
  const [notifications, setNotifications] = useState([]);
  const [time, setTime] = useState("");
  const navigate = useNavigate();
  const { userData, dispatch } = useContext(userContext);
  const [isLoading, setIsLoading] = useState(false);
  const [hasNotifications, setHasNotifications] = useState(false);

  function handleLogout() {
    try {
      dispatch({ type: "switchAccount", payload: "live" });
      dispatch({ type: "logout" });
      signOut(auth);
      navigate("/login");
      toast.success("Logged Out");
    } catch (error) {
      console.log(error);
    }

    setTimeout(() => {}, 800);
  }

  useEffect(() => {
    async function getNoti() {
      const notiList = [];
      const q = query(
        collection(db, "notifications"),
        where("user", "==", user.uid)
      );
      const querySnapshot = await getDocs(q);
      if (querySnapshot.empty) {
        return;
      } else {
        setHasNotifications(true);
        querySnapshot.forEach((doc) => {
          notiList.push(doc.data());
        });
      }

      setNotifications(notiList);
    }

    if (!loading && user) {
      setLoader(false);
      getNoti();
    }
  }, []);

  async function getNoti() {
    const notiList = [];
    const q = query(
      collection(db, "notifications"),
      where("user", "==", user.uid)
    );
    const querySnapshot = await getDocs(q);
    if (querySnapshot.empty) {
      return;
    } else {
      setHasNotifications(true);
      querySnapshot.forEach((doc) => {
        notiList.push(doc.data());
      });
    }

    setNotifications(notiList);
  }

  useEffect(() => {
    if (noti) {
      setLoader(false);
    }
  }, [noti, time]);

  const handleClickAway = () => {
    setNoti(false);
  };

  async function deleteAllNotis(notis) {
    try {
      setIsLoading(true);
      await notis.forEach((not) => {
        deleteNoti(not.ref);
      });
      setIsLoading(true);
      setNoti(false);
      setHasNotifications(false);
      getNoti();
      toast.success("Deleted all notifications.");
    } catch (error) {
      console.log(error);
      toast.error("Please try again later.");
    }
  }

  async function deleteNoti(ref) {
    try {
      await deleteDoc(doc(db, "notifications", ref));
      getNoti();
    } catch (error) {}
  }

  return (
    <Wrapper>
      <div
        className="left"
        onClick={(e) => {
          e.preventDefault();
          setIsOpen(!isOpen);
        }}
      >
        <img src="/sidebaricons/hamburger.svg" alt="hamburger" />
      </div>
      <div className="right">
        <span className="bell">
          <div className="relative">
            <img
              src="/sidebaricons/notification.svg"
              onClick={() => setNoti(!noti)}
              alt="notifications"
            />
            {hasNotifications && (
              <span className="w-2 h-2 animate-bounce rounded-full bg-red-500 absolute top-0 right-[2px]"></span>
            )}
          </div>

          <div className="notiMenu">
            {noti && (
              <ClickAwayListener onClickAway={handleClickAway}>
                <Grow
                  in={noti}
                  style={{ transformOrigin: "0 0 0 0" }}
                  {...(noti ? { timeout: 300 } : {})}
                >
                  <Menu className="menu scrollbar-hidden">
                    <div
                      className="top"
                      style={{ position: "sticky", zIndex: "1000" }}
                    >
                      <BodyText>Notifications</BodyText>
                      <button onClick={() => setNoti(!noti)} className="close">
                        <img src="/sidebaricons/close.svg" alt="close" />
                      </button>
                    </div>

                    <div className="mid">
                      {loader ? (
                        <CircularLoader />
                      ) : notifications.length > 0 ? (
                        <>
                          <Notifications
                            data={notifications}
                            loading={{ isLoading, setIsLoading }}
                            close={{ noti, setNoti }}
                            hasNotis={{ hasNotifications, setHasNotifications }}
                          />
                        </>
                      ) : (
                        <p style={{ padding: "3rem" }} className="font-medium">
                          No notifications right now.
                        </p>
                      )}
                    </div>

                    {notifications.length > 0 && (
                      <div className="bottom">
                        <OutlinedButton
                          className="mb-8"
                          onClick={() => deleteAllNotis(notifications)}
                        >
                          <p className="red">Delete all</p>
                        </OutlinedButton>
                      </div>
                    )}
                  </Menu>
                </Grow>
              </ClickAwayListener>
            )}
          </div>
        </span>

        <div>
          {userData ? (
            <>
              {userData.photoURL ? (
                <img
                  src={userData?.photoURL}
                  alt="profile"
                  className="profile"
                  onClick={() => setProfile(!profile)}
                />
              ) : (
                <span className="avatar" onClick={() => setProfile(!profile)}>
                  <>
                    {!userData.firstname && (
                      <img src="/svg-loaders/tail-spin.svg" alt="loading" />
                    )}
                    <p>{userData.firstname?.slice(0, 1)}</p>
                  </>
                </span>
              )}
            </>
          ) : (
            <img
              src="/svg-loaders/tail-spin.svg"
              alt="loading"
              width={24}
              height={24}
            />
          )}

          {profile && (
            <ClickAwayListener onClickAway={() => setProfile(!profile)}>
              <Grow
                in={profile}
                style={{ transformOrigin: "0 0 0 0" }}
                {...(profile ? { timeout: 300 } : {})}
              >
                <ProfileCard style={{ minWidth: "230px" }}>
                  <div className="options">


                    <div className="flex py-4 px-3 gap-2 hover:bg-[#1f273a] rounded-lg items-center cursor-pointer">
                      <span>
                        <Link to="/dashboard">
                          Home
                          </Link>
                      </span>

                    </div>
                    <div className="flex py-4 px-3 gap-2 hover:bg-[#1f273a] rounded-lg items-center cursor-pointer">
                      <span>
                        <Link to="/profile">
                          Profile
                          </Link>
                      </span>
                    </div>

                    <div className="flex py-4 px-3 gap-2 hover:bg-[#1f273a] rounded-lg items-center cursor-pointer">
                      <span>
                        <Link to="/profile">
                          Settings
                          </Link>
                      </span>
                    </div>

                    {/* <p onClick={() => navigate("/profile")}>Profile</p> */}
                    {/* <p onClick={() => navigate("/profile")}>Settings</p> */}
                  </div>
                  <OutlinedButton>
                    <p className="blue" onClick={handleLogout}>
                      Logout
                    </p>
                  </OutlinedButton>
                </ProfileCard>
              </Grow>
            </ClickAwayListener>
          )}
        </div>
        {/* <Menu>Hello</Menu> */}
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  background-color: #0e121b;
  grid-area: head;
  min-width: 100%;
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  position: sticky;
  z-index: 50;
  top: 0;
  padding: 20px;
  align-items: center;

  .avatar {
    width: 35px;
    place-content: center;
    height: 35px;
    align-items: center;
    /* padding: 0.5rem; */
    background-color: #c4c4c4;
    display: flex;
    color: #131824;
    border-radius: 50%;
  }

  .profile {
    width: 35px;
    height: 35px;
    object-fit: cover;
    border-radius: 50%;
  }

  .menu {
    position: fixed;
    border: 1px solid #19202f;
    max-width: 100vw;
    box-sizing: border-box;
  }

  .close {
    background-color: transparent;
    outline: none;
    border: none;

    img {
      width: 15px;
    }
  }

  .logo {
    width: 26px;
    height: 26px;
    background-color: #c4c4c4;
    border-radius: 50%;
    cursor: pointer;
  }

  img {
    height: 20px;
    width: 20px;
    cursor: pointer;
  }

  .right {
    display: flex;
    gap: 1rem;
    align-items: center;
  }

  .bell:hover {
    background-color: rgba(214, 214, 214, 0.12);
  }

  .bell {
    position: relative;
    cursor: pointer;
    border-radius: 4px;
    padding: 0.5rem;

    p {
      cursor: pointer;
    }
  }
`;

export default Header;
