import { Menu, SubText } from "../styles";
import { useEffect, useState, useRef } from "react";
import styled from "styled-components";
import {
  collection,
  query,
  getDocs,
  orderBy,
  doc,
  updateDoc,
  setDoc,
  serverTimestamp,
  where,
} from "firebase/firestore";
import { db } from "../firebase/firebase";
import CircularLoader from "../pages/CircularLoader";
import { ClickAwayListener, Grow } from "@mui/material";
import ModalImage from "react-modal-image";
import toast from "react-hot-toast";
import emailjs from "@emailjs/browser";

const Verifications = ({ user }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [verifications, setVerifications] = useState([]);
  const [users, setUsers] = useState([]);
  const [action, setAction] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [userName, setUserName] = useState("");
  const [id, setID] = useState("");

  useEffect(() => {
    const verificationsList = [];
    async function getVerifications() {
      const q = query(
        collection(db, "verifications"),
        where("user", "==", user.id)
      );
      //   orderBy("date", "desc")
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        verificationsList.push(doc.data());
        setVerifications(verificationsList);
      });
    }

    getVerifications();
  }, [user]);

  useEffect(() => {
    if (!verifications) {
      setIsLoading(true);
    }

    if (verifications) {
      setTimeout(() => {
        setIsLoading(false);
      }, 1000);
    }
  }, [verifications]);

  useEffect(() => {
    const userList = [];
    async function getUsers() {
      const q = query(collection(db, "users"));
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        userList.push(doc.data());
        setUsers(userList);
      });
    }

    getUsers();
  }, []);

  useEffect(() => {
    if (!users) {
      setIsLoading(true);
    }

    if (users) {
      setTimeout(() => {
        setIsLoading(false);
      }, 1000);
    }
  }, [users]);

  function getTime(milliSeconds) {
    const seconds = milliSeconds / 1000;
    const minutes = seconds / 60;
    const hours = minutes / 60;
    const days = hours / 24;
    const weeks = days / 7;

    if (seconds > 60 && minutes < 60) {
      return (
        Math.floor(minutes) +
        (Math.floor(minutes) > 1 ? " minutes ago" : " minute ago")
      );
    }

    if (seconds > 60 && minutes > 60 && hours < 24) {
      return (
        Math.floor(hours) + (Math.floor(hours) > 1 ? " hours ago" : " hour ago")
      );
    }

    if (seconds > 60 && minutes > 60 && hours > 24 && days < 7) {
      return (
        Math.floor(days) + (Math.floor(days) > 1 ? " days ago" : " day ago")
      );
    }

    if (seconds > 60 && minutes > 60 && hours > 24 && days > 7) {
      return (
        Math.floor(weeks) + (Math.floor(weeks) > 1 ? " weeks ago" : " week ago")
      );
    }

    return (
      Math.floor(seconds) +
      (Math.floor(seconds) > 1 ? " seconds ago" : " seconds ago")
    );
  }

  const cardRef = useRef();
  const [activeRef, setActiveRef] = useState("");

  const [approvedVerifications, setApprovedVerifications] = useState([]);
  const [declinedVerifications, setDeclinedVerifications] = useState([]);
  const [pendingVerifications, setPendingVerifications] = useState([]);

  useEffect(() => {
    const approvedVerificationsList = [];
    const declinedVerificationsList = [];
    const pendingVerificationsList = [];

    verifications.forEach((verification) => {
      switch (verification.status) {
        case "pending":
          pendingVerificationsList.push(verification);
          break;
        case "approved":
          approvedVerificationsList.push(verification);
          break;
        case "declined":
          declinedVerificationsList.push(verification);
          break;
        default:
          break;
      }
    });

    setApprovedVerifications(approvedVerificationsList);
    setPendingVerifications(pendingVerificationsList);
    setDeclinedVerifications(declinedVerificationsList);
  }, [
    verifications,
    approvedVerifications,
    declinedVerifications,
    pendingVerifications,
  ]);

  // Opens the menu
  function handleVerify(user, type, ref) {
    setActiveRef(ref);
    setID(user);
  }

  useEffect(() => {
    if (id) {
      users.forEach((us) => {
        if (us.id === id) {
          setUserEmail(us.email);
          setUserName(us.firstname);
        }
      });
    }
  }, [id]);

  // Determines if approve, withdraw or request and calls the functions
  function handleAction(act, ref, user) {
    setActiveRef("");
    setAction(ref);
    if (act === "approve") {
      // console.log(userEmail);
      // sendApproveEmail(ref, user);
      handleApprove(ref, user);
    }

    if (act === "decline") {
      // sendDeclineEmail(ref, user);
      handleDecline(ref, user, act);
    }

    if (act === "request") {
      // sendRequestEmail(ref, user, act);
      handleDecline(ref, user, act);
    }
  }

  // Approval Email and calls to change doc status
  async function sendApproveEmail(ref, user) {
    const params = {
      Code: "Approved",
      user_name: userName,
      message:
        " Your verification request has been accepted and approved  successfully.",
      detail: "The update will be reflected on your next login.",
      from_name: "Global Capitals",
      reply_to: "support@globalcapitalsgc.com",
      to_email: userEmail,
      logo_link: "https://globalcapitalsgc.com/logo.png",
      from_email: "helpdesk@globalcapitalsgc.com",
      email_support: "mailto:support@globalcapitalsgc.com",
      link_to: "globalcapitalsgc.com",
      broker_link_to: "https://app.globalcapitalsgc.com",
    };

    emailjs
      .send("service_mwsptkh", "template_reim8ur", params, "9IOr2_lHheTH7RW1k")
      .then(() => {
        handleApprove(ref, user);
      })
      .catch((error) => {
        toast.error("There was an error with this approval");
        console.log(error);
        setAction("");
      });
  }

  // changes the staus of the verification doc and calls to approve the user
  async function handleApprove(ref, user) {
    const selected = doc(db, "verifications", ref);
    await updateDoc(selected, {
      status: "approved",
    })
      .then(() => {
        handleUserApprove(user);
      })
      .catch((error) => {
        toast.error(
          "There was a problem with your request.\n \n Please try again later."
        );
        setAction("");
      });
  }

  // Changes the user status and calls to send notification
  async function handleUserApprove(user) {
    const profile = doc(db, "users", user);
    await updateDoc(profile, {
      verified: true,
    })
      .then(() => {
        setApproveNotification(user);
      })
      .catch((error) => {
        toast.error(
          "There was a problem with your request.\n \n Please try again later."
        );
        setAction("");
      });
  }

  //set approve notification
  async function setApproveNotification(user) {
    const randomOne = Math.floor(Math.random(100, 999) * 1000 + 1);
    const randomTwo = Math.floor(Math.random(100, 999) * 100 + 1);

    const str =
      id.substring(0, 4) + randomOne.toString() + randomTwo.toString();

    await setDoc(doc(db, "notifications", str), {
      date: serverTimestamp(),
      ref: str,
      user,
      title: "Verification",
      text: "Your verification was successful.",
    })
      .then(() => {
        toast.success("Approved successfully");
        setAction("");
      })
      .catch((error) => {
        toast.error("Approval could not complete.");
      });
  }

  // Decline Email and calls to change doc status
  async function sendDeclineEmail(ref, user) {
    const params = {
      Code: "Declined",
      user_name: userName,
      message:
        "We regret to inform you that your verification request has been declined.",
      detail:
        "Kindly contact your account manager or chat support for further information.",
      from_name: "Global Capitals",
      logo_link: "https://globalcapitalsgc.com/logo.png",
      reply_to: "support@globalcapitalsgc.com",
      to_email: userEmail,
      from_email: "helpdesk@globalcapitalsgc.com",
      email_support: "mailto:support@globalcapitalsgc.com",
      link_to: "globalcapitalsgc.com",
      broker_link_to: "https://app.globalcapitalsgc.com",
    };

    emailjs
      .send("service_mwsptkh", "template_reim8ur", params, "9IOr2_lHheTH7RW1k")
      .then(() => {
        handleDecline(ref, user);
      })
      .catch((error) => {
        toast.error("There was an error with this approval");
        console.log(error);
        setAction("");
      });
  }

  // changes the staus of the verification doc and calls to decline the user
  async function handleDecline(ref, user, act) {
    const selected = doc(db, "verifications", ref);
    await updateDoc(selected, {
      status: "declined",
    })
      .then(() => {
        handleUserDecline(user, act);
      })
      .catch((error) => {
        toast.error(
          "There was a problem with your request.\n \n Please try again later."
        );
        setAction("");
      });
  }

  // Changes the user status and calls to send notification
  async function handleUserDecline(user, act) {
    const profile = doc(db, "users", user);
    await updateDoc(profile, {
      verified: false,
    })
      .then(() => {
        setDeclineNotification(act, user);
      })
      .catch((error) => {
        toast.error(
          "There was a problem with your request.\n \n Please try again later."
        );
        setAction("");
      });
  }

  async function setDeclineNotification(act, user) {
    const randomOne = Math.floor(Math.random(100, 999) * 1000 + 1);
    const randomTwo = Math.floor(Math.random(100, 999) * 100 + 1);

    const str =
      id.substring(0, 4) + randomOne.toString() + randomTwo.toString();

    await setDoc(doc(db, "notifications", str), {
      date: serverTimestamp(),
      ref: str,
      user,
      title: "Verification",
      text: "Your verification failed.",
    })
      .then(() => {
        if (act === "request") {
          toast.success("Verification requested");
        } else {
          toast.success("Declined successfully");
        }
        setAction("");
      })
      .catch((error) => {
        toast.error("Decline could not complete.");
      });
  }

  // Decline Email and calls to change doc status
  async function sendRequestEmail(ref, user, act) {
    const params = {
      action_desc: "means of verification",
      Action: "Verification",
      user_name: userName,
      message: "verify your account",
      detail: "Kindly login to your account to complete this request.",
      login_to: "https://app.globalcapitalsgc.com",
      from_name: "Global Capitals",
      logo_link: "https://globalcapitalsgc.com/logo.png",
      website: "https://globalcapitalsgc.com",
      website_to: "globalcapitalsgc.com",
      email_to: "mailto:support@globalcapitalsgc.com",
      to_email: userEmail,
      from_email: "helpdesk@globalcapitalsgc.com",
      reply_to: "support@globalcapitalsgc.com",
    };

    emailjs
      .send("service_mwsptkh", "template_232iwnq", params, "9IOr2_lHheTH7RW1k")
      .then(() => {
        handleDecline(ref, user, act);
      })
      .catch((error) => {
        toast.error("There was an error with this request.");
        console.log(error);
        setAction("");
      });
  }

  return (
    <Wrapper>
      <SubText className="top">Verifications</SubText>
      {isLoading ? (
        <CircularLoader />
      ) : (
        <table className="scrollbar-hidden">
          <thead>
            <tr>
              <th>User</th>
              <th>Ref</th>
              <th>Type</th>
              <th>Date</th>
              <th>View</th>
              <th style={{ textAlign: "center" }}>Status</th>
            </tr>
          </thead>
          <tbody>
            {pendingVerifications.map(
              (verification) =>
                verification.status === "pending" && (
                  <tr key={verification.ref} className="row">
                    <td>
                      {users.map(
                        (user) =>
                          user.id === verification.user && (
                            <p
                              key={verification.ref}
                              style={{ textTransform: "Capitalize" }}
                            >
                              {user.firstname}
                            </p>
                          )
                      )}
                    </td>
                    <td className="ref">
                      <p>{verification.ref}</p>
                    </td>
                    <td>
                      <p>{verification.type}</p>
                    </td>

                    <td>
                      <p> {getTime(new Date() - verification.date.toDate())}</p>
                      {/* {user.isVerified && <button>active</button>} */}
                      {/* {!user.isVerified && <button>passive</button>} */}
                    </td>
                    <td
                      style={{
                        position: "relative",
                        display: "flex",
                        justifyContent: "flex-start",
                        margin: "0",
                        padding: "0",
                      }}
                    >
                      <button
                        style={{
                          pointerEvents: "none",
                          // position: "absolute",
                        }}
                        className="actionButton"
                      >
                        <p className="actionText">View</p>
                      </button>
                      <ModalImage
                        large={verification.link}
                        alt="verification"
                        className="modal"
                      />
                    </td>
                    <td>
                      {verification.status === "pending" && (
                        <div style={{ position: "relative" }}>
                          <button
                            ref={cardRef}
                            className="pending"
                            style={{
                              width: "100%",
                              boxSizing: "border-box",
                              padding: "0.21rem",
                              display: "grid",
                              placeContent: "center",
                              height: "42px",
                            }}
                            disabled={action}
                            onClick={() =>
                              handleVerify(
                                verification.user,
                                verification.type,
                                verification.ref
                              )
                            }
                          >
                            {/* <img src="/extraicons/action.svg" alt="action" /> */}
                            {action === verification.ref ? (
                              <img
                                src="/svg-loaders/tail-spin.svg"
                                alt="loading"
                                width={24}
                                height={24}
                              />
                            ) : (
                              <p>Pending</p>
                            )}
                          </button>
                          {activeRef === verification.ref && (
                            <>
                              <ClickAwayListener
                                onClickAway={() => setActiveRef("")}
                              >
                                <Grow
                                  in={activeRef === verification.ref}
                                  style={{ transformOrigin: "0 0 0 0" }}
                                  {...(activeRef === verification.ref
                                    ? { timeout: 300 }
                                    : {})}
                                >
                                  <div className="actionMenu">
                                    <p
                                      onClick={() =>
                                        handleAction(
                                          "approve",
                                          verification.ref,
                                          verification.user
                                        )
                                      }
                                    >
                                      Approve
                                    </p>
                                    <p
                                      onClick={() =>
                                        handleAction(
                                          "decline",
                                          verification.ref,
                                          verification.user
                                        )
                                      }
                                    >
                                      Decline
                                    </p>
                                    <p
                                      onClick={() =>
                                        handleAction(
                                          "request",
                                          verification.ref,
                                          verification.user
                                        )
                                      }
                                    >
                                      Request another verification
                                    </p>
                                  </div>
                                </Grow>
                              </ClickAwayListener>
                            </>
                          )}
                        </div>
                      )}
                    </td>
                  </tr>
                )
            )}
            {approvedVerifications.map(
              (verification) =>
                verification.status === "approved" && (
                  <tr key={verification.ref} className="row">
                    <td>
                      {users.map(
                        (user) =>
                          user.id === verification.user && (
                            <p
                              key={verification.ref}
                              style={{ textTransform: "Capitalize" }}
                            >
                              {user.firstname}
                            </p>
                          )
                      )}
                    </td>
                    <td className="ref">
                      <p>{verification.ref}</p>
                    </td>
                    <td>
                      <p>{verification.type}</p>
                    </td>

                    <td>
                      <p>{getTime(new Date() - verification.date.toDate())}</p>
                      {/* {user.isVerified && <button>active</button>} */}
                      {/* {!user.isVerified && <button>passive</button>} */}
                    </td>
                    <td
                      style={{
                        position: "relative",
                        display: "flex",
                        justifyContent: "flex-start",
                        margin: "0",
                        padding: "0",
                      }}
                    >
                      <button
                        style={{
                          pointerEvents: "none",
                          // position: "absolute",
                        }}
                        className="actionButton"
                      >
                        <p className="actionText">View</p>
                      </button>
                      <ModalImage
                        large={verification.link}
                        alt="verification"
                        className="modal"
                      />
                    </td>
                    <td>
                      {verification.status === "approved" && (
                        <button
                          disabled
                          className="approved"
                          style={{
                            width: "100%",
                            boxSizing: "border-box",
                            padding: "0.21rem",
                          }}
                        >
                          <p>Approved</p>
                        </button>
                      )}
                    </td>
                  </tr>
                )
            )}
            {declinedVerifications.map(
              (verification) =>
                verification.status === "declined" && (
                  <tr key={verification.ref} className="row">
                    <td>
                      {users.map(
                        (user) =>
                          user.id === verification.user && (
                            <p
                              key={verification.ref}
                              style={{ textTransform: "Capitalize" }}
                            >
                              {user.firstname}
                            </p>
                          )
                      )}
                    </td>
                    <td className="ref">
                      <p>{verification.ref}</p>
                    </td>
                    <td>
                      <p>{verification.type}</p>
                    </td>

                    <td>
                      <p> {getTime(new Date() - verification.date.toDate())}</p>
                      {/* {user.isVerified && <button>active</button>} */}
                      {/* {!user.isVerified && <button>passive</button>} */}
                    </td>
                    <td
                      style={{
                        position: "relative",
                        display: "flex",
                        justifyContent: "flex-start",
                        margin: "0",
                        padding: "0",
                      }}
                    >
                      <button
                        style={{
                          pointerEvents: "none",
                          // position: "absolute",
                        }}
                        className="actionButton"
                      >
                        <p className="actionText">View</p>
                      </button>
                      <ModalImage
                        large={verification.link}
                        alt="verification"
                        className="modal"
                      />
                    </td>
                    <td>
                      {verification.status === "declined" && (
                        <button
                          disabled
                          className="declined"
                          style={{
                            width: "100%",
                            boxSizing: "border-box",
                            padding: "0.21rem",
                          }}
                        >
                          <p>Declined</p>
                        </button>
                      )}
                    </td>
                  </tr>
                )
            )}
          </tbody>
        </table>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
  margin-top: 30px;
  background: #131824;
  border-radius: 10px;
  max-width: 100%;

  .modal {
    opacity: 0;
    margin: 0;
    padding: 0;
    width: 100%;
    position: absolute;
    right: 0;
    height: 100%;
  }

  .declined {
    /* border: 0.5px solid #1199fa; */
    border: none;
    border-radius: 6px;
    outline: none;
    background: rgba(230, 75, 96, 0.13);

    p {
      font-size: 14px;
      line-height: 19px;
      font-weight: 600;
      padding: 0.5rem;
      color: #e64b60;
    }
  }

  .approved {
    /* border: 0.5px solid #1199fa; */
    border: none;
    border-radius: 6px;
    outline: none;
    background: rgba(32, 188, 164, 0.13);

    p {
      font-size: 14px;
      line-height: 19px;
      font-weight: 600;
      padding: 0.5rem;
      color: #20bca4;
    }
  }

  .pending {
    background: rgba(35, 163, 251, 0.13);
    border-radius: 6px;
    border: none;
    outline: none;

    p {
      font-size: 14px;
      line-height: 19px;
      font-weight: 600;
      padding: 0.5rem 1rem;
      color: #1199fa;
    }
  }

  .actionMenu {
    appearance: none;
    position: absolute;
    left: -100px;
    z-index: 1000;
    top: -100px;
    /* bottom: -40px; */
    border: 0.5px solid #1f273a;
    background: #161c2a;
    border-radius: 8px;
    padding: 0.4rem;
    display: grid;
    gap: 0.5rem;

    p {
      padding: 0.5rem;
      cursor: pointer;
      transition: background-color 300ms cubic-bezier(0.075, 0.82, 0.165, 1);
    }

    p:hover {
      background-color: #1f273a;
      border-radius: 4px;
    }
  }

  .asset {
    display: flex;
    gap: 0.5rem;
    align-items: center;
  }
  .top {
    top: 0;
    width: 100%;
    background-color: #19202f;
    display: flex;
    justify-content: space-between;
    padding: 1rem;
    box-sizing: border-box;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }

  table {
    display: grid;
    gap: 1rem;
    padding: 1rem;
    overflow-x: auto;
    max-width: 100%;
    overflow-x: auto;

    .name {
      display: flex;
      align-items: center;
      gap: 0.8rem;
      text-transform: capitalize;

      img {
        border-radius: 50%;
        object-fit: cover;
      }
    }

    .ref,
    .email {
      box-sizing: border-box;

      p {
        text-overflow: ellipsis;
        max-width: 85px;
        overflow: hidden;
      }
    }
  }

  thead {
    border: 1px solid #1f273a;
    box-sizing: border-box;
    border-radius: 4px;
    font-weight: 700;
    color: #828ea1;
    font-size: 14px;
    line-height: 18px;
  }

  tbody {
    font-weight: 510;
    font-size: 14px;
    line-height: 17px;
    color: #ffffff;
    display: grid;
    gap: 0.7rem;
    padding-bottom: 30px;

    .row {
      border-radius: 4px;
    }

    .row:hover {
      background-color: #1f273a;
    }
  }

  th,
  td {
    width: 144px;
    text-align: left;
  }

  tr {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 1rem;
    align-items: center;
    box-sizing: border-box;
  }
`;

export default Verifications;
