import styled from "styled-components";
import { Header, Sidebar } from "../components/index";
import { useContext, useEffect, useState } from "react";
import {
  TitleText,
  Content,
  PageSwitcher,
  Card,
  FeatureText,
  SubText,
} from "../styles";
import { UsersTable, VerificationTable } from "../tables";
import SingleUser from "./SingleUser";
import PaymentsTable from "../tables/PaymentsTable";
import WithdrawalsTable from "../tables/WithdrawalTable";
import SubscriptionsTable from "../tables/SubscriptionTables";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../firebase/firebase";
import { useNavigate } from "react-router-dom";
import Loader from "./Loader";
import { userContext } from "../context/userContext";
import { useMediaQuery } from "@mui/material";
import TradeTable from "../tables/TradeTable";
import { collection, query, getDocs } from "firebase/firestore";
import { db } from "../firebase/firebase";
import StakingsTable from "../tables/StakingsTable";
import NFTSTable from "../tables/NFTSTable";
import TokensTable from "../tables/TokensTable";
import Web3UsersTable from "../tables/Web3UsersTable";
import NormalStakingsTable from "../tables/NormalStakingsTable";

const Manage = () => {
  const [menuSmall, setMenuSmall] = useState(false);
  const mobile = useMediaQuery("(max-width: 900px)");
  const [isOpen, setIsOpen] = useState(mobile ? false : true);
  const pages = [
    "Overview",
    "Users",
    "Verifications",
    "Payments",
    "Withdrawals",
    "Subscriptions",
    "Trades",
    "Stakings",
    // "Web3",
    // "NFTs",
    // "Tokens",
    "NFT Stakings",
  ];
  const [activePage, setActivePage] = useState("Overview");
  const [singleUser, setSingleUser] = useState(false);
  const [userId, setUserId] = useState("abc");
  const navigate = useNavigate();
  const [user, loading] = useAuthState(auth);
  const [loader, setLoader] = useState(user !== null ? false : true);
  const { userData } = useContext(userContext);

  useEffect(() => {
    if (!loading && user && userData) {
      if (userData.admin) {
        setLoader(false);
      } else {
        navigate("/dashboard");
      }
    }

    if (!loading && !user) {
      navigate("/login");
    }
  }, [user, loading, navigate, userData]);

  function handlePageSwitch(e) {
    if (singleUser) {
      setSingleUser(false);
    }
    setActivePage(e.target.value);
  }

  const [activeUsers, setActiveUsers] = useState([]);
  const [blockedUsers, setBlockedUsers] = useState([]);
  const [approvedPayments, setApprovedPayments] = useState([]);
  const [declinedPayments, setDeclinedPayments] = useState([]);
  const [pendingPayments, setPendingPayments] = useState([]);
  const [approvedVerifications, setApprovedVerifications] = useState([]);
  const [declinedVerifications, setDeclinedVerifications] = useState([]);
  const [pendingVerifications, setPendingVerifications] = useState([]);
  const [approvedWithdrawals, setApprovedWithdrawals] = useState([]);
  const [declinedWithdrawals, setDeclinedWithdrawals] = useState([]);
  const [pendingWithdrawals, setPendingWithdrawals] = useState([]);
  const [payments, setPayments] = useState([]);
  const [users, setUsers] = useState([]);
  const [withdrawals, setWithdrawals] = useState([]);
  const [verifications, setVerifications] = useState([]);
  const [trades, setTrades] = useState([]);
  const [subscriptions, setSubscriptions] = useState([]);
  const [runningTrades, setRunningTrades] = useState([]);
  const [fulfilledTrades, setFulfilledTrades] = useState([]);
  const [activeSubscriptions, setActiveSubscriptions] = useState([]);
  const [closedSubscriptions, setClosedSubscriptions] = useState([]);

  useEffect(() => {
    const verificationsList = [];
    async function getVerifications() {
      const q = query(collection(db, "verifications"));
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        verificationsList.push(doc.data());
        setVerifications(verificationsList);
      });
    }

    getVerifications();
  }, []);

  useEffect(() => {
    const approvedVerificationsList = [];
    const pendingVerificationsList = [];
    const declinedVerificationsList = [];

    verifications.forEach((verification) => {
      switch (verification.status) {
        case "pending":
          pendingVerificationsList.push(verification);
          break;
        case "approved":
          approvedVerificationsList.push(verification);
          break;
        case "declined":
          declinedVerificationsList.push(verification);
          break;
        default:
          break;
      }
    });

    setApprovedVerifications(approvedVerificationsList.length);
    setPendingVerifications(pendingVerificationsList.length);
    setDeclinedVerifications(declinedVerificationsList.length);
  }, [
    verifications,
    approvedVerifications,
    declinedVerifications,
    pendingVerifications,
  ]);

  useEffect(() => {
    const paymentsList = [];
    async function getPayments() {
      const q = query(collection(db, "deposits"));
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        paymentsList.push(doc.data());
        setPayments(paymentsList);
      });
    }

    getPayments();
  }, []);

  useEffect(() => {
    const approvedPaymentsList = [];
    const pendingPaymentsList = [];
    const declinedPaymentsList = [];

    payments.forEach((payment) => {
      switch (payment.status) {
        case "pending":
          pendingPaymentsList.push(payment);
          break;
        case "approved":
          approvedPaymentsList.push(payment);
          break;
        case "declined":
          declinedPaymentsList.push(payment);
          break;
        default:
          break;
      }
    });

    setApprovedPayments(approvedPaymentsList.length);
    setPendingPayments(pendingPaymentsList.length);
    setDeclinedPayments(declinedPaymentsList.length);
  }, [payments, approvedPayments, declinedPayments, pendingPayments]);

  useEffect(() => {
    const withdrawalsList = [];
    async function getWithdrawals() {
      const q = query(collection(db, "withdrawals"));
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        withdrawalsList.push(doc.data());
        setWithdrawals(withdrawalsList);
      });
    }

    getWithdrawals();
  }, []);

  useEffect(() => {
    const approvedWithdrawalsList = [];
    const pendingWithdrawalsList = [];
    const declinedWithdrawalsList = [];

    withdrawals.forEach((withdrawal) => {
      switch (withdrawal.status) {
        case "pending":
          pendingWithdrawalsList.push(withdrawal);
          break;
        case "approved":
          approvedWithdrawalsList.push(withdrawal);
          break;
        case "declined":
          declinedWithdrawalsList.push(withdrawal);
          break;
        default:
          break;
      }
    });

    setApprovedWithdrawals(approvedWithdrawalsList.length);
    setPendingWithdrawals(pendingWithdrawalsList.length);
    setDeclinedWithdrawals(declinedWithdrawalsList.length);
  }, [
    withdrawals,
    approvedWithdrawals,
    declinedWithdrawals,
    pendingWithdrawals,
  ]);

  useEffect(() => {
    const usersList = [];
    async function getUsers() {
      const q = query(collection(db, "users"));
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        usersList.push(doc.data());
        setUsers(usersList);
      });
    }

    getUsers();
  }, []);

  useEffect(() => {
    const activeUsersList = [];
    const blockedUsersList = [];

    users.forEach((user) => {
      switch (user.blocked) {
        case false:
          activeUsersList.push(user);
          break;
        case true:
          blockedUsersList.push(user);
          break;
        default:
          break;
      }
    });

    setActiveUsers(activeUsersList.length);
    setBlockedUsers(blockedUsersList.length);
  }, [users, blockedUsers, activeUsers]);

  useEffect(() => {
    const tradesList = [];
    async function getTrades() {
      const q = query(collection(db, "trades"));
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        tradesList.push(doc.data());
        setTrades(tradesList);
      });
    }

    getTrades();
  }, []);

  useEffect(() => {
    const runningTradesList = [];
    const fulfilledTradesList = [];

    trades.forEach((trade) => {
      switch (trade.status) {
        case "open":
          runningTradesList.push(trade);
          break;
        case "closed":
          fulfilledTradesList.push(trade);
          break;
        default:
          break;
      }
    });

    setRunningTrades(runningTradesList.length);
    setFulfilledTrades(fulfilledTradesList.length);
  }, [trades, fulfilledTrades, runningTrades]);

  useEffect(() => {
    const subscriptionsList = [];
    async function getSubscriptions() {
      const q = query(collection(db, "subscriptions"));
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        subscriptionsList.push(doc.data());
        setSubscriptions(subscriptionsList);
      });
    }

    getSubscriptions();
  }, []);

  useEffect(() => {
    const activeSubscriptionsList = [];
    const closedSubscriptionsList = [];

    subscriptions.forEach((subscription) => {
      switch (subscription.status) {
        case "open":
          activeSubscriptionsList.push(subscription);
          break;
        case "closed":
          closedSubscriptionsList.push(subscription);
          break;
        default:
          break;
      }
    });

    setActiveSubscriptions(activeSubscriptionsList.length);
    setClosedSubscriptions(closedSubscriptionsList.length);
  }, [subscriptions, closedSubscriptions, activeSubscriptions]);

  return (
    <>
      {loader ? (
        <Loader />
      ) : (
        <Wrapper>
          <Sidebar
            menu={[menuSmall, setMenuSmall]}
            active="Manage"
            open={[isOpen, setIsOpen]}
          />
          <Main>
            <Header
              menu={[menuSmall, setMenuSmall]}
              open={[isOpen, setIsOpen]}
            />
            <Content>
              <TitleText>Manage</TitleText>
              <br />

              <PageSwitcher className="switcher scrollbar-hidden">
                {pages.map((page) => (
                  <button
                    style={{ padding: "0.8rem" }}
                    key={page}
                    value={page}
                    className={page === activePage ? "active" : ""}
                    onClick={handlePageSwitch}
                  >
                    {page}
                  </button>
                ))}
              </PageSwitcher>

              {activePage === "Overview" && (
                <ContentWrapper>
                  <Card>
                    <SubText className="top">Users</SubText>
                    <div
                      className="body"
                      style={{
                        padding: "1rem",
                        display: "grid",
                        gap: "0.5rem",
                        justifyContent: "space-between",
                      }}
                    >
                      <FeatureText style={{ display: "flex", gap: "0.45rem" }}>
                        <p className="blue">Active</p>
                        <span>~{activeUsers}</span>
                      </FeatureText>

                      <FeatureText style={{ display: "flex", gap: "0.45rem" }}>
                        <p className="red">Blocked</p>
                        <span>~{blockedUsers}</span>
                      </FeatureText>
                    </div>
                  </Card>

                  <Card>
                    <SubText className="top">Deposits</SubText>
                    <div
                      className="body"
                      style={{
                        padding: "1rem",
                        display: "grid",
                        gap: "0.5rem",
                        justifyContent: "space-between",
                      }}
                    >
                      <FeatureText style={{ display: "flex", gap: "0.45rem" }}>
                        <p className="blue">Pending</p>
                        <span>~{pendingPayments}</span>
                      </FeatureText>

                      <FeatureText style={{ display: "flex", gap: "0.45rem" }}>
                        <p className="red" style={{ color: "#20bca4" }}>
                          Approved
                        </p>
                        <span>~{approvedPayments}</span>
                      </FeatureText>

                      <FeatureText style={{ display: "flex", gap: "0.45rem" }}>
                        <p className="red">Declined</p>
                        <span>~{declinedPayments}</span>
                      </FeatureText>
                    </div>
                  </Card>

                  <Card>
                    <SubText className="top">Withdrawals</SubText>
                    <div
                      className="body"
                      style={{
                        padding: "1rem",
                        display: "grid",
                        gap: "0.5rem",
                        justifyContent: "space-between",
                      }}
                    >
                      <FeatureText style={{ display: "flex", gap: "0.45rem" }}>
                        <p className="blue">Pending</p>
                        <span>~{pendingWithdrawals}</span>
                      </FeatureText>

                      <FeatureText style={{ display: "flex", gap: "0.45rem" }}>
                        <p className="red" style={{ color: "#20bca4" }}>
                          Approved
                        </p>
                        <span>~{approvedWithdrawals}</span>
                      </FeatureText>

                      <FeatureText style={{ display: "flex", gap: "0.45rem" }}>
                        <p className="red">Declined</p>
                        <span>~{declinedWithdrawals}</span>
                      </FeatureText>
                    </div>
                  </Card>
                  {/* <Card>
                    <TitleText className="top">Plans</TitleText>
                    <p>Active: 20</p>
                    <p>Disabled: 10</p>
                  </Card> */}

                  <Card>
                    <SubText className="top">Subscriptions</SubText>
                    <div
                      className="body"
                      style={{
                        padding: "1rem",
                        display: "grid",
                        gap: "0.5rem",
                        justifyContent: "space-between",
                      }}
                    >
                      <FeatureText style={{ display: "flex", gap: "0.45rem" }}>
                        <p className="blue">Running</p>
                        <span>~{activeSubscriptions}</span>
                      </FeatureText>

                      <FeatureText style={{ display: "flex", gap: "0.45rem" }}>
                        <p className="red">Ended</p>
                        <span>~{closedSubscriptions}</span>
                      </FeatureText>
                    </div>
                  </Card>

                  <Card>
                    <SubText className="top">Trades</SubText>
                    <div
                      className="body"
                      style={{
                        padding: "1rem",
                        display: "grid",
                        gap: "0.5rem",
                        justifyContent: "space-between",
                      }}
                    >
                      <FeatureText style={{ display: "flex", gap: "0.45rem" }}>
                        <p className="blue">Running</p>
                        <span>~{runningTrades}</span>
                      </FeatureText>

                      <FeatureText style={{ display: "flex", gap: "0.45rem" }}>
                        <p className="red">Fulfilled</p>
                        <span>~{fulfilledTrades}</span>
                      </FeatureText>
                    </div>
                  </Card>

                  <Card>
                    <SubText className="top">Verifications</SubText>
                    <div
                      className="body"
                      style={{
                        padding: "1rem",
                        display: "grid",
                        gap: "0.5rem",
                        justifyContent: "space-between",
                      }}
                    >
                      <FeatureText style={{ display: "flex", gap: "0.45rem" }}>
                        <p className="blue">Pending</p>
                        <span>~{pendingVerifications}</span>
                      </FeatureText>

                      <FeatureText style={{ display: "flex", gap: "0.45rem" }}>
                        <p className="red" style={{ color: "#20bca4" }}>
                          Approved
                        </p>
                        <span>~{[approvedVerifications]}</span>
                      </FeatureText>

                      <FeatureText style={{ display: "flex", gap: "0.45rem" }}>
                        <p className="red">Declined</p>
                        <span>~{declinedVerifications}</span>
                      </FeatureText>
                    </div>
                  </Card>
                </ContentWrapper>
              )}

              {activePage === "Users" && !singleUser && (
                <UsersTable
                  open={{ singleUser, setSingleUser }}
                  id={{ userId, setUserId }}
                />
              )}

              {singleUser && <SingleUser id={userId} />}

              {activePage === "Verifications" && (
                <VerificationTable id={userId} />
              )}
              {activePage === "Stakings" && <NormalStakingsTable id={userId} />}
              {activePage === "Payments" && <PaymentsTable id={userId} />}
              {activePage === "Withdrawals" && <WithdrawalsTable id={userId} />}
              {activePage === "Subscriptions" && (
                <SubscriptionsTable id={userId} />
              )}
              {activePage === "Trades" && <TradeTable id={userId} />}
              {activePage === "NFT Stakings" && <StakingsTable />}
              {activePage === "NFTs" && <NFTSTable />}
              {activePage === "Tokens" && <TokensTable />}
              {activePage === "Web3" && <Web3UsersTable />}
              <br />
              <br />
            </Content>
          </Main>
        </Wrapper>
      )}
    </>
  );
};

const Wrapper = styled.div`
  display: flex;
  background: #0e121b;
  color: white;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
`;

const Main = styled.div`
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  overflow-y: auto;

  .switcher {
    width: 100%;
    display: flex;
    overflow-x: auto;
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  gap: 1rem;
  height: fit-content;
  margin-top: 50px;
  width: 100%;
  overflow-x: auto;

  @media screen and (max-width: 1200px) {
    display: grid;
    grid-template-columns: repeat(auto, 4);
    grid-template-rows: repeat(4, auto);
  }

  @media screen and (max-width: 800px) {
    display: grid;
  }
`;

export default Manage;
